export default {
  namespaced: true,
  name: 'shipping_methods',

  translatedAttributes: [
    'name',
    'description',
    'terms',
  ],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => ({
    id: item.id,
    name_en: item.name.en,
    name_ar: item.name.ar,
    courier_code: item.courier.code,
    notes_en: item.notes.en,
    notes_ar: item.notes.ar,
    is_active: item.is_active,
    is_cod_allowed: item.is_cod_allowed,
    is_courier_controls_rates: item.is_courier_controls_rates,
    driver_app_settings: item.driver_app_settings,
    rates: item.rates.map(r => {
      const newRate = JSON.parse(JSON.stringify(r))
      newRate.cities = newRate.cities.filter(c => c !== 'country')
      newRate.fixed_cost *= 100
      newRate.extra_weight_cost *= 100
      return newRate
    }),
  }),

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => ({
    ...initialItemData,
    courier: {
      code: data.courierCode,
      label: data.courierName,
    },
    courierData: data.courierData,
    name: {
      en: data.name_en,
      ar: data.name_ar,
    },
    notes: {
      en: data.notes_en,
      ar: data.notes_ar,
    },
    rates: data.rates.map(rate => ({
      id: rate.id,
      cities: rate.is_whole_country ? ['country'] : rate.cities,
      is_whole_country: rate.is_whole_country,
      is_fixed_cost: !!rate.is_fixed_cost,
      fixed_cost: rate.fixed_cost / 100,
      weight_threshold: rate.weight_threshold,
      extra_weight_cost: rate.extra_weight_cost / 100,
      extra_weight_grams: rate.extra_weight_grams,
    })),
    is_cod_allowed: data.isCodAllowed,
    is_courier_controls_rates: data.is_courier_controls_rates,
    is_active: data.isActive,
  }),

  resolveValidationErrors: errors => ({
    id: errors.id,
    name: {
      en: errors.name_en,
      ar: errors.name_ar,
    },
    notes: {
      en: errors.notes_en,
      ar: errors.notes_ar,
    },
    // category: errors.categories || errors?.['categories.0'],
    // images: errors?.['products.0.images'],
  }),
}
