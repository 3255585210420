<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <h5
        v-t="'modules.waybills._create.tabs.basic_info'"
        class="mb-0"
      />
      <small
        v-t="'modules.waybills._create.tabs.basic_info:description'"
        class="text-muted"
      />
    </b-col>
    <b-col md="6">
      <i-input
        v-model="value.courier"
        :errors="validationErrors.courier_code"
        type="select"
        title="modules.shipping_methods.inputs.courier"
        :options="options"
        :clearable="true"
        required
        option-label="title"
        :disabled="isLoadingCourierSettingOptions"
        @input="v => $emit('select-courier', v)"
      >
        <!--        <template #list-footer>-->
        <!--          <p-->
        <!--            v-t="`modules.shipping_methods.inputs.courier:footer`"-->
        <!--            class="pl-50 pt-50"-->
        <!--          />-->
        <!--        </template>-->
      </i-input>
    </b-col>
    <b-col md="6">

      <i-input
        v-model="value.service_type"
        :errors="validationErrors.service_type_code"
        type="select"
        title="modules.waybills._create.inputs.service_type"
        :options="courierSettingOptions.service_types"
        :clearable="true"
        required
        label="title"
        :disabled="isLoadingCourierSettingOptions"
      />
    </b-col>
    <b-col md="12">
      <i-input
        v-model="value.warehouse"
        :errors="validationErrors.courier_code"
        type="select"
        title="modules.waybills._create.inputs.warehouse"
        description="modules.waybills._create.inputs.warehouse:description"
        :options="courierSettingOptions.warehouses"
        :clearable="true"
        required
        label="title"
        :disabled="isLoadingCourierSettingOptions"
      />
    </b-col>
    <!--    <b-col md="12">-->
    <!--      <i-input-->
    <!--        v-model="value.notes"-->
    <!--        :errors="validationErrors.notes"-->
    <!--        type="textarea"-->
    <!--        title="modules.shipping_methods.inputs.note"-->
    <!--        description="modules.shipping_methods.inputs.note:description"-->
    <!--        rows="2"-->
    <!--        :translatable="true"-->
    <!--      />-->
    <!--    </b-col>-->
    <template v-if="isCourierSupports('cod')">
      <b-col md="6">
        <i-input
          v-model="value.has_cod"
          :errors="validationErrors.has_cod"
          type="switch"
          title="modules.waybills._create.inputs.has_cod"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </i-input>
      </b-col>
      <b-col md="6">
        <i-input
          v-if="value.has_cod"
          v-model="value.cod.amount"
          :errors="validationErrors.cod_amount"
          type="price"
          :currency="value.cod.currency"
          title="modules.waybills._create.inputs.cod_amount"
        />
      </b-col>
    </template>
    <template v-if="isCourierSupports('open_box')">
      <b-col md="12">
        <i-input
          v-model="value.allow_open_box"
          :errors="validationErrors.has_cod"
          type="switch"
          title="modules.waybills._create.inputs.allow_open_box"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </i-input>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  emits: ['input', 'select-courier'],
  props: {
    options: {
      type: Array,
      required: true,
      default: Array,
    },
    value: {
      type: Object,
      required: true,
    },
    isLoadingCourierSettingOptions: {
      type: Boolean,
      default: false,
    },
    courierSettingOptions: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const isCourierSupports = featureCode => {
      if (props.isLoadingCourierSettingOptions) {
        return false
      }
      return props.courierSettingOptions.supports.indexOf(featureCode) !== -1
    }

    return {
      validationErrors: {},
      isCourierSupports,
    }
  },
}
</script>
