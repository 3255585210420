<template>
  <b-modal
    id="modal-import-products-from-old-store"
    ref="refModel"
    :title="$t('modules.products._modals.import_from_other_store_cta.title')"
    :ok-title="$t('Submit')"
    :cancel-title="$t('Cancel')"
    cancel-variant="outline-secondary"
    :ok-disabled="isProductOperationRequestLoading || isProductOperationPending || !requestPayload.platform || !requestPayload.url"
    :cancel-disabled="isProductOperationRequestLoading || isProductOperationPending"
    :no-close-on-backdrop="isProductOperationRequestLoading || isProductOperationPending"
    :no-close-on-esc="isProductOperationRequestLoading || isProductOperationPending"
    :hide-header-close="isProductOperationRequestLoading || isProductOperationPending"
    :busy="isProductOperationRequestLoading || isProductOperationPending"
    :hide-footer="isProductOperationPending"
    ok-only
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <template v-if="isProductOperationPending">
      <div class="d-flex align-items-center">
        <span class="mr-auto"><b-spinner /></span>
        <p class="pt-50 pl-50">
          {{ $t('modules.products._messages.wait_for_importing_operation_to_finish') }}
        </p>
      </div>
    </template>
    <template v-else>
      <form
        ref="refForm"
        @submit.stop.prevent="handleSubmit"
      >
        <b-card-text>
          {{ $t('modules.products._modals.import_from_other_store_cta.select_platform') }}
        </b-card-text>

        <hr>
        <b-form-group>
          <list-group-selectable
            :options="platformOptions"
            key-attribute="key"
            label-attribute="label"
            label-position="after"
            mode="horizontal"
            :value="requestPayload.platform"
            @input="v => requestPayload.platform = v"
          />
        </b-form-group>

        <b-form-group
          :state="validationState"
          label-for="csv-file-input"
          invalid-feedback="Store URL is required"
        >
          <Transition name="fade-bottom">
            <i-input
              v-if="requestPayload.platform"
              id="store-url"
              v-model="requestPayload.url"
              type="url"
              :title="$t('modules.products._modals.import_from_other_store_cta.enter_url')"
              :placeholder="selectedPlatform ? selectedPlatform.placeholder : 'https://xxxxxxxxxx'"
              required
              :aria-invalid="validationState"
              :disabled="isProductOperationRequestLoading"
              :readonly="isProductOperationRequestLoading"
              :aria-readonly="isProductOperationRequestLoading"
              :input-bindings="{
                style: 'direction: ltr; text-align: left',
              }"
            />
          </Transition>
        </b-form-group>
      </form>
    </template>
  </b-modal>
</template>

<script>
import { computed, onUnmounted, ref } from 'vue'
import {
  BCardText, BFormGroup, BModal, BSpinner,
} from 'bootstrap-vue'
import { saveAs } from 'file-saver'
import {
  commonRequestErrorsHandler,
  mediaPath, storagePath, toast,
} from '@core/utils/utils'
import store from '@/store'
import productOperationStoreModule from '@/views/models/products/productOperationStoreModule'
import ListGroupSelectable from '@/views/components/list-group/ListGroupSelectable.vue'
import {useUtils as useI18nUtils} from "@core/libs/i18n";

// import 'swiper/css/swiper.css'

const SHOPIFY_LOGO = require('@/assets/images/logos/platforms/shopify.svg')
const SLLR_LOGO = require('@/assets/images/logos/platforms/sllr-icon.svg')
const EASY_ORDERS_LOGO = require('@/assets/images/logos/platforms/easy-orders.png')

export default {
  components: {
    ListGroupSelectable,
    BCardText,
    BSpinner,
    BModal,
    BFormGroup,
  },
  directives: {
  },
  emits: ['operation-finished'],
  methods: {
    storagePath,
    saveAs,
  },
  setup(props, ctx) {
    const OPERATIONS_STORE_MODULE_NAME = 'product_operations'
    // Register module
    if (!store.hasModule(OPERATIONS_STORE_MODULE_NAME)) store.registerModule(OPERATIONS_STORE_MODULE_NAME, productOperationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OPERATIONS_STORE_MODULE_NAME)) store.unregisterModule(OPERATIONS_STORE_MODULE_NAME)
    })

    const refForm = ref(null)
    const refModel = ref(null)
    const validationState = ref(null)
    const emptyRequestPayload = {
      operation: 'import_external',
      platform: null,
      url: null,
    }
    const requestPayload = ref({ ...emptyRequestPayload })
    const isProductOperationRequestLoading = ref(false)
    const isProductOperationPending = ref(false)
    const isProductOperationRequestFinished = ref(false)
    const activeOperationId = ref(null)
    const { t } = useI18nUtils()
    const checkFormValidity = () => {
      const valid = refForm.value.checkValidity()
      validationState.value = valid
      return valid
    }
    const resetModal = () => {
      requestPayload.value = { ...emptyRequestPayload }
      validationState.value = null
      isProductOperationRequestLoading.value = false
      isProductOperationPending.value = false
      activeOperationId.value = null
    }
    const getOperationStatus = () => {
      store.dispatch(`${OPERATIONS_STORE_MODULE_NAME}/fetchOne`, activeOperationId.value)
        .then(response => {
          if (response.status === 'pending') {
            setTimeout(() => { getOperationStatus() }, 5000)
          } else if (response.status === 'finished') {
            isProductOperationRequestLoading.value = false
            isProductOperationPending.value = false
            isProductOperationRequestFinished.value = true
            toast('success', 'Products added successfully')
            ctx.emit('operation-finished')
            refModel.value.toggle()
          } else {
            toast('error', 'Something went wrong!')
          }
        })
        .catch(e => {
          isProductOperationRequestLoading.value = false
          refModel.value.toggle()

          if (!commonRequestErrorsHandler(e, false)) {
            toast('danger', 'Something went wrong! Please try again later.', null, 'AlertTriangleIcon')
          }
        })
    }
    const handleSubmit = () => {
      if (!checkFormValidity()) {
        return
      }

      store.dispatch(`${OPERATIONS_STORE_MODULE_NAME}/create`, requestPayload.value)
        .then(response => {
          if (response.operation_id) {
            activeOperationId.value = response.operation_id
            isProductOperationPending.value = true
            getOperationStatus()
          } else if (response.message) {
            toast(response.success ? 'success' : 'info', '', response.message)
            refModel.value.toggle()
          } else {
            toast('error', 'Something went wrong!')
          }
        })
        .catch(e => {
          isProductOperationRequestLoading.value = false

          commonRequestErrorsHandler(e)

          refModel.value.toggle()
        })
    }
    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmit()
    }

    const onClickDownloadSample = () => {
      const cdn = new URL(mediaPath())

      saveAs(`${cdn.protocol}//${cdn.host}/static/files/import-csv-sample.csv`, 'kssib-sample.csv')
    }

    const platformOptions = ref([])
    // TODO: Fetch from BE instead
    platformOptions.value = [
      {
        key: 'shopify',
        label: 'Shopify \n شوبيفاي',
        logo: SHOPIFY_LOGO,
        placeholder: 'https://xxxxxx.myshopify.com',
      },
      {
        key: 'sllr',
        label: 'Sllr \n سلر',
        logo: SLLR_LOGO,
        placeholder: 'https://sllr.co/xxxxxx/',
      },
      {
        key: 'easy-orders',
        label: 'Easy Orders \n ايزي اوردرز',
        logo: EASY_ORDERS_LOGO,
        placeholder: 'https://xxxx.myeasyorders.com',
      },
      {
        key: 'other',
        label: t('Other'),
        icon: 'GlobeIcon',
        placeholder: 'https://xxxxxxxx.com',
      },
    ]

    const selectedPlatform = computed(() => {
      if (!requestPayload.value.platform) {
        return null
      }

      return platformOptions.value.filter(platform => platform.key === requestPayload.value.platform)[0] || null
    })

    return {
      refForm,
      refModel,
      validationState,
      requestPayload,
      isProductOperationRequestLoading,
      isProductOperationPending,
      isProductOperationRequestFinished,
      platformOptions,
      selectedPlatform,

      checkFormValidity,
      resetModal,
      handleOk,
      handleSubmit,
      onClickDownloadSample,
    }
  },
}
</script>
