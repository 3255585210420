<template>
  <b-modal
    id="modal-create-waybill"
    ref="modal"
    :visible.sync="isVisible"
    centered
    :title="$t('modules.waybills._create.title')"
    sub-title="modules.waybills._create.subtitle"
    size="lg"
    hide-footer
    :ok-title="$t('Create')"
    :cancel-title="$t('Cancel')"
    body-class="p-0"
    @show="onShow"
    @hidden="$emit('hidden')"
  >
    <b-overlay
      :show="isLoading || isLoadingCourierSettingOptions"
      rounded="sm"
      variant="secondary"
      opacity="0.75"
    >
      <form-wizard
        ref="refFormObserver"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('Save')"
        :back-button-text="$t('Previous')"
        :next-button-text="$t('Next')"
        :start-index="0"
        :color="$themeColors.primary"
        @on-complete="onSubmit"
      >
        <!-- account details tab -->
        <tab-content
          :title="$t('modules.waybills._create.tabs.basic_info').toString()"
          :before-change="validationBasicSettingsForm"
          :icon="itemData.id ? 'feather icon-file-text' : null"
          :disabled="true"
        >
          <validation-observer
            ref="basicSettingsRules"
            tag="form"
          >
            <basic-info-tab
              ref="basicSettingsTab"
              v-model="itemData"
              :options="courierOptions"
              :courier-setting-options="courierSettingOptions"
              :is-loading-courier-setting-options="isLoadingCourierSettingOptions"
              @select-courier="v=>fetchCourierSettingOptions(v)"
              @input="v => itemData=v"
            />
          </validation-observer>
        </tab-content>

        <!-- rates tab -->
        <tab-content
          :title="$t('modules.waybills._create.tabs.package_details').toString()"
          :before-change="validationShippingRatesForm"
          :icon="itemData.id ? 'feather  icon-dollar-sign' : null"
          :disabled="isLoadingCourierSettingOptions"
        >
          <validation-observer
            ref="shippingRatesRules"
            tag="form"
          >
            <shipment-items-tab
              v-model="itemData"
              :cities="cities"
              :courier-setting-options="courierSettingOptions"
              :is-loading-courier-setting-options="isLoadingCourierSettingOptions"
            />
          </validation-observer>
        </tab-content>

        <!-- TODO: Future feature -->
        <!-- company information tab -->
        <tab-content
          v-if="0 && itemData.courier && itemData.courier.provider"
          title="Shipping Company Info"
          :before-change="validationCompanyInfoForm"
        >
          <validation-observer
            ref="companyInfoRules"
            tag="form"
          >
            <!--          <company-information-tab />-->
          </validation-observer>
        </tab-content>

        <!-- integration details tab -->
        <tab-content
          v-if="0 && itemData.courier && itemData.courier.provider"
          title="Integration Info"
          :before-change="validationIntegrationSettingsForm"
        >
          <validation-observer
            ref="integrationSettingsRules"
            tag="form"
          >
            <!--          <integration-settings-tab />-->
          </validation-observer>
        </tab-content>

      </form-wizard>
    </b-overlay>
  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import store from '@/store'
import {
  nextTick, onMounted, onUnmounted, ref,
} from 'vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import Ripple from 'vue-ripple-directive'
import ShipmentItemsTab from '@/views/models/waybills/add/ShipmentItemsTab.vue'
import BasicInfoTab from '@/views/models/waybills/add/BasicInfoTab.vue'
import { handleBadRequestError, handleValidationError, toast } from '@core/utils/utils'
import { $themeColors } from '@themeConfig'
import { BModal, BOverlay } from 'bootstrap-vue'
import orderStoreModule from '@/views/models/orders/orderStoreModule'
import waybillStoreModule from '@/views/models/waybills/waybillStoreModule'
import courierStoreModule from '@/views/models/couriers/courierStoreModule'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
// import CompanyInformationTab from '@/views/models/waybills/add/CompanyInformationTab.vue'
// import IntegrationSettingsTab from '@/views/models/waybills/add/IntegrationSettingsTab.vue'

export default {
  components: {
    BOverlay,
    BModal,
    ValidationObserver,
    FormWizard,
    TabContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ShipmentItemsTab,
    BasicInfoTab,
    // CompanyInformationTab,
    // IntegrationSettingsTab,
  },
  directives: {
    Ripple,
  },
  emits: ['submitted', 'hidden', 'update:is-visible'],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    orderData: {
      type: Object,
      default: null,
    },
    shipmentStatusOptions: {
      type: Array,
      default: Array,
    },

    itemId: {
      type: String,
      required: false,
      default: null,
    },
    preselectedCourierCode: {
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: false,
      default: Array,
    },
    cities: {
      type: Array,
      required: false,
      default: Array,
    },
  },
  computed: {
    $themeColors() {
      return $themeColors
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  methods: {
    validationBasicSettingsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.basicSettingsRules.validateWithInfo().then(({ success, /* errors, */ fields }) => {
          if (success) {
            resolve(true)
          } else {
            const failedFields = Object.keys(fields).filter(key => fields[key].failed)
            if (failedFields.length) {
              toast('warning', this.$t('message.fill_required_fields'))
              this.focusOnTabsOfErrors(failedFields, this.$refs.basicSettingsTab.$refs)
              reject()
            } else {
              resolve(true)
            }
          }
        })
      })
    },
    validationCompanyInfoForm() {
      return new Promise((resolve, reject) => {
        this.$refs.companyInfoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationIntegrationSettingsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.integrationSettingsRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationShippingRatesForm() {
      return new Promise((resolve, reject) => {
        this.$refs.shippingRatesRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
  setup(props, ctx) {
    const ORDER_STORE_MODULE_NAME = 'orders'
    const COURIER_STORE_MODULE_NAME = 'couriers'
    const WAYBILL_STORE_MODULE_NAME = 'waybills'

    // Register module
    if (!store.hasModule(ORDER_STORE_MODULE_NAME)) store.registerModule(ORDER_STORE_MODULE_NAME, orderStoreModule)
    if (!store.hasModule(COURIER_STORE_MODULE_NAME)) store.registerModule(COURIER_STORE_MODULE_NAME, courierStoreModule)
    if (!store.hasModule(WAYBILL_STORE_MODULE_NAME)) store.registerModule(WAYBILL_STORE_MODULE_NAME, waybillStoreModule)
    onMounted(() => {
      if (!store.hasModule(ORDER_STORE_MODULE_NAME)) store.registerModule(ORDER_STORE_MODULE_NAME, orderStoreModule)
      if (!store.hasModule(COURIER_STORE_MODULE_NAME)) store.registerModule(COURIER_STORE_MODULE_NAME, courierStoreModule)
      if (!store.hasModule(WAYBILL_STORE_MODULE_NAME)) store.registerModule(WAYBILL_STORE_MODULE_NAME, waybillStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDER_STORE_MODULE_NAME)) store.unregisterModule(ORDER_STORE_MODULE_NAME)
      if (store.hasModule(COURIER_STORE_MODULE_NAME)) store.unregisterModule(COURIER_STORE_MODULE_NAME)
      if (store.hasModule(WAYBILL_STORE_MODULE_NAME)) store.unregisterModule(WAYBILL_STORE_MODULE_NAME)
    })

    // Data definition
    const { t } = useI18nUtils()
    const refs = ref({})
    const modal = ref()
    const emptyCourierOption = { code: null, label: null }
    const emptyItemData = {
      order_id: null,
      order_short_id: null,
      recipient: {
        name: null,
        mobile: null,
      },
      shipping_address: {
        line_1: null,
        line_2: null,
        lat: null,
        long: null,
        short_address: null,
        description: null,
        city: null,
        city_name: null,
        state: {
          id: null,
          title: null,
          iso2: null,
        },
        country: {
          id: null,
          title: null,
        },
      },
      weight_in_gm: 0,
      is_all_items_have_weight: true,
      cod: {
        amount: '0',
        currency: 'EGP',
      },
      items: [],
      couriers: [],

      // Inputs
      courier: {
        code: emptyCourierOption.code,
        label: emptyCourierOption.label,
      },
      warehouse: null,
      service_type: null,
      package_size: null,
      package_weight: 0.0,
      has_cod: false,
      allow_open_box: false,
      notes: {},
    }

    const itemData = ref({
      ...emptyItemData,
    })
    const isLoading = ref(true)
    const rates = ref([])
    const courierOptions = ref([])
    const isLoadingCourierSettingOptions = ref(false)
    const courierSettingOptions = ref({
      warehouses: [],
      service_types: [],
      package_sizes: [],
      supports: [],
    })

    const fetchCourierSettingOptions = courier => {
      if (!courier.code) {
        courierSettingOptions.value.warehouses = []
        courierSettingOptions.value.service_types = []
        courierSettingOptions.value.package_sizes = []
        return
      }
      isLoadingCourierSettingOptions.value = true
      store.dispatch(`${COURIER_STORE_MODULE_NAME}/fetchOne`, courier.code)
        .then(response => {
          courierSettingOptions.value.warehouses = response.warehouses
          courierSettingOptions.value.service_types = response.service_types
          courierSettingOptions.value.package_sizes = response.package_sizes
          courierSettingOptions.value.supports = response.supports

          itemData.value.warehouse = courierSettingOptions.value.warehouses[0] || null
          itemData.value.service_type = courierSettingOptions.value.service_types[0] || null
          itemData.value.package_size = courierSettingOptions.value.package_sizes[0] || null
        })
        .catch(e => {
          if (!handleValidationError(e)) {
            if (!handleBadRequestError(e)) {
              const title = typeof (t) === 'function' ? t('message.operation_failed') : 'Error'
              toast('danger', title, '', 'AlertTriangleIcon')
            }
          }
        })
        .finally(() => {
          isLoadingCourierSettingOptions.value = false
        })
    }

    const fetchDraftWaybillData = () => {
      if (!props.itemId) {
        return
      }
      isLoading.value = true
      store.dispatch(`${WAYBILL_STORE_MODULE_NAME}/fetchOptions`, props.itemId).then(data => {
        // itemData.value = {
        //   ...itemData.value,
        //   ...data,
        // }
        if (!data.items.length) {
          return
        }
        itemData.value.order_id = data.order_id
        itemData.value.order_short_id = data.order_short_id
        itemData.value.recipient = data.recipient
        itemData.value.shipping_address = data.shipping_address
        itemData.value.weight_in_gm = data.weight_in_gm
        itemData.value.is_all_items_have_weight = data.is_all_items_have_weight
        itemData.value.cod.amount = data.cod.amount / 100
        itemData.value.cod.currency = data.cod.currency
        itemData.value.items = data.items
        itemData.value.supports = data.supports
        courierOptions.value = data.couriers
        // Changes based on response
        itemData.value.has_cod = (data.amount && data.amount.cod !== '0') || false
        itemData.value.courier = data.couriers.filter(courier => courier.default)[0] || { ...emptyCourierOption }
        fetchCourierSettingOptions(itemData.value.courier)
        itemData.value.package_weight = (data.weight_in_gm / 1000) || 1
      }).finally(() => {
        isLoading.value = false
      })
    }

    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(emptyItemData))
      isLoading.value = false
    }

    resetItemData()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    const {
      validationErrors, fetch, performSave,
      focusOnTabsOfErrors,
    } = useModelUpdate(
      WAYBILL_STORE_MODULE_NAME,
      refs,
      itemData,
      () => {
        modal.value.hide()
        ctx.emit('update:is-visible', false)
        ctx.emit('submitted')
        toast('success', t('modules.waybills._create._messages.waybill_created_successfully'))
      },
      null,
      null,
      () => {
        // refs.value.value.sidebar.hide()
        itemData.value = JSON.parse(JSON.stringify(emptyItemData))

        ctx.emit('update:is-visible', false)
      },
    )

    const fetchItem = () => fetch().then(() => refFormObserver.value.activateAll())

    // const fetchOrder = () => fetch().then(() => refFormObserver.value.activateAll())

    const onShow = () => {
      resetForm()
      // fetchItem()

      fetchDraftWaybillData()

      nextTick(() => {
        refFormObserver.value.reset()
      })
    }

    const onSubmit = () => {
      isLoading.value = true
      performSave(true,
        () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        })
    }

    return {
      STORE_MODULE_NAME: WAYBILL_STORE_MODULE_NAME,
      itemData,
      courierOptions,
      isLoadingCourierSettingOptions,
      courierSettingOptions,
      rates,
      fetchItem,
      fetchCourierSettingOptions,
      validationErrors,
      onShow,
      onSubmit,

      // Refs
      modal,
      refFormObserver,
      getValidationState,
      focusOnTabsOfErrors,
      resetForm,

      refs,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard';
[dir] .modal-content .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  box-shadow: 0 3px 6px 0 rgba(73, 227, 112, 0.4);
}
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
