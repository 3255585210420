<template>
  <b-card
    class="list-view order-items-list"
  >
    <b-card-title class="d-flex align-items-center justify-content-between mb-1">
      <label class="section-label">
        <feather-icon
          :icon="isExpanded ? `ChevronDownIcon` : `ChevronUpIcon`"
          size="28"
          class="text-secondary pr-50 cursor-pointer"
          @click="isExpanded = !isExpanded"
        />
        <span
          v-b-tooltip:hover="$t(`modules.orders._shipments.shipment_number`)"
          :title="$t(`modules.orders._shipments.shipment_number`)"
        >
          <feather-icon
            icon="HashIcon"
            class="mr-25"
          />
          {{ shipment.short_id }}
        </span>

        <b-badge
          v-b-tooltip:hover="`${ $t(`modules.orders._shipments.status`) }`"
          :title="`${ $t(`modules.orders._shipments.status`) }`"
          class="ml-1"
          :variant="`light-${resolveShipmentStatusVariant(shipment.status)}`"
        >
          {{ shipment.status_description }}
        </b-badge>
      </label>
      <label class="section-label">
        {{ $t(`modules.orders._shipments.tracking_no`) }}: {{ shipment.tracking_id }}

        <a
          v-if="shipment.tracking_url"
          :href="shipment.tracking_url"
          target="_blank"
        >
          <feather-icon icon="ExternalLinkIcon" />
        </a>

        <span class="ml-1">
          <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :text="$t(`modules.orders._shipments.actions`)"
            variant="flat"
            right
            class="dropdown-icon-wrapper"
            toggle-class="p-0"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="28"
                class="text-secondary"
              />
            </template>
            <b-dropdown-item
              v-for="action in shipment.actions"
              :key="`dropdown-status-${action.code}`"
              @click="$emit('click-action', action.code)"
            >
              <feather-icon
                v-if="action.icon"
                :icon="action.icon"
              />
              {{ action.title }}
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </label>
    </b-card-title>

    <b-collapse
      v-model="isExpanded"
      :visible="isExpanded"
      class="card-content"
    >
      <div class="checkout-items">
        <b-card
          v-for="product in shipment.items"
          :key="product.id"
          class="ecommerce-card shipment-item-card"
          no-body
          bg-variant="light"
        >

          <!-- Product Image -->
          <div class="item-img">
            <b-img
              class="sm-img"
              height="100px"
              width="auto"
              :src="product.image ? storagePath(product.image) : ImagePlaceholder"
              :alt="`${product.name}-${product.id}`"
            />
          </div>

          <!-- Product Details: Card Body -->
          <b-card-body>
            <div class="item-name">
              <h6 class="mb-0">
                <b-link
                  class="text-body"
                  :to="product.product_group_id ? {name: 'products.edit', params: {id: product.product_group_id}} : null"
                  target="_blank"
                >
                  {{ product.title }}
                </b-link>
              </h6>
              <span class="item-company"><b-link class="company-name">{{ product.category }}</b-link></span>
            </div>
            <ul
              v-if="product.options_values && product.options_values.length"
              class="mb-1"
            >
              <li
                v-for="(optionValue, k) in product.options_values"
                :key="`option-value-${k}`"
                class="text-muted"
              >
                {{ optionValue[`option_${$i18n.locale}`] }}: {{ optionValue[`value_${$i18n.locale}`] }}
              </li>
            </ul>
            <div class="item-quantity">
              <span class="quantity-title pr-25">{{ $t('Qty') }}:</span>
              <span>{{ product.qty }}</span>
              <span class="mx-1">x</span>
              <span>{{ product.unit_price.string }}</span>
            </div>
          </b-card-body>

          <!-- Product Options/Actions -->
          <div class="item-options text-center">
            <div class="item-wrapper">
              <div class="item-cost">
                <h4 class="item-price">
                  {{ product.subtotal.string }}
                </h4>
                <p
                  v-if="product.free_shipping"
                  class="card-text shipping"
                >
                  <b-badge
                    v-t="`Free Shipping`"
                    pill
                    variant="light-success"
                  />
                </p>
              </div>
            </div>
          </div>
        </b-card>

        <b-alert
          v-if="!shipment.items.length"
          variant="warning"
          class="mt-1"
          show
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="AlertTriangleIcon"
            />
            <span
              v-t="`modules.orders._create._messages.no_items`"
              class="ml-25"
            />
          </div>
        </b-alert>
      </div>
    </b-collapse>
    <b-card-sub-title class="d-flex justify-content-between">
      <label
        v-b-tooltip:hover="$t(shipment.courier.name ? 'modules.shipping_methods.inputs.courier' : 'modules.shipping_methods._create.custom_shipping_company')"
        class="section-label"
      >
        <feather-icon
          icon="TruckIcon"
          class="mr-25"
        /> {{ shipment.courier.name }}
      </label>
      <label class="section-label">
        <feather-icon
          icon="ClockIcon"
          class="mr-25"
        />
        {{ $t(`modules.orders._shipments.last_update_date`) }}:
        {{ shipment.last_update_date ? formatDatetime(shipment.last_update_date) : formatDatetime(shipment.created_at) }}
      </label>
    </b-card-sub-title>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BBadge,
  VBToggle,
  BAlert, BCardSubTitle, VBTooltip, BDropdown, BDropdownItem, BCollapse,
} from 'bootstrap-vue'
import { formatDate, formatDatetime } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { BCardTitle } from 'bootstrap-vue/src/components/card'
import { storagePath } from '@core/utils/utils'
import { resolveShipmentStatusVariant } from '@core/utils/constants'
import { ref } from 'vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  emit: ['click-action'],
  components: {
    BCollapse,
    BDropdownItem,
    BDropdown,
    BCardSubTitle,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BBadge,

    BCardTitle,
    BAlert,
  },
  props: {
    options: {
      type: Array,
      default: Array,
    },
    selected: {
      type: Object,
      default: Object,
    },
    shipment: {
      type: Object,
      default: Object,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  methods: { resolveShipmentStatusVariant, formatDatetime, storagePath },
  setup(props, ctx) {
    const ImagePlaceholder = require('@/assets/images/pages/eCommerce/placeholder.webp')
    const isShipmentImportant = ['cancelled', 'refused'].indexOf(props.shipment.status) === -1
    const isExpanded = ref(isShipmentImportant)
    console.log(props.shipment.status)
    return {
      isExpanded,

      // UI
      ImagePlaceholder,

      // Filter
      formatDate,
    }
  },
}
</script>

<style>
[dir] .order-items-list .v-select ul {
  position: absolute;
  top: auto;
  left: auto;
  width: inherit;
}

[dir] .ecommerce-application .ecommerce-card.empty-card:hover {
  transform: unset;
  box-shadow: unset;
}
.ecommerce-application .list-view .ecommerce-card {
  overflow: unset !important;
}

[dir] .ecommerce-application .checkout-items .ecommerce-card .item-img img.sm-img {
  height: 100px;
  width: auto;
}

.checkout-items .card-body .qty-price-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.checkout-items .card-body .qty-price-row .row-item {
  margin: 0.5rem 1rem;
  flex: 1 1 12rem;
  min-width: 12rem;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
[dir="rtl"] .ecommerce-application .list-view .shipment-item-card .card-body {
  border-left: 1px solid #dadada;
}
</style>
