<template>
  <b-form-group
    :label="label"
    title=""
    :description="description"
  >
    <b-button-group class="d-flex align-items-center">
      <b-button
        v-for="option in options"
        :key="option.value"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="option.value === localValue ? 'primary' : 'outline-primary'"
        @click="select(option)"
      >
        <feather-icon
          v-if="option.value === localValue"
          icon="CheckIcon"
          class="mr-50"
        />
        <span>{{ option.label }}</span>
      </b-button>
    </b-button-group>
  </b-form-group>
</template>

<script>
import { BButtonGroup, BButton, BFormGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from 'vue'

export default {
  components: {
    BFormGroup,
    BButtonGroup,
    BButton,
  },
  directives: {
    Ripple,
  },
  emits: ['input', 'update:value'],
  props: {
    options: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: [String, Number, Array],
      default: () => null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const localValue = ref(props.value)

    const select = option => {
      localValue.value = option.value
      emit('update:value', localValue)
      emit('input', localValue)
    }

    return {
      localValue,
      select,
    }
  },
}
</script>
