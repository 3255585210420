<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        md="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Info -->
        <div class="d-flex justify-content-start mb-1">

          <b-avatar
            :src="null /* For future use */"
            :text="avatarText(itemData.customer.name)"
            :variant="itemData.customer.id ? `light-primary` : `light-secondary`"
            size="64"
          />
          <div class="d-flex flex-column ml-1">
            <div
              v-if="itemData.customer && itemData.customer.id"
              class="mb-1"
            >
              <h4 class="mb-0">
                {{ itemData.customer.name }}

                <b-badge
                  v-if="itemData.customer.is_guest"
                  pill
                  variant="light-secondary"
                >
                  <small>
                    {{ $t(`Guest`) }}
                  </small>
                </b-badge>
              </h4>
              <div class="card-text pt-25">
                {{ itemData.customer.email }}
                <b-badge
                  v-if="itemData.customer.email"
                  pill
                  :variant="`light-` + resolveBooleanStatusVariant(!!itemData.customer.email_verified_at)"
                >
                  {{ itemData.customer.email_verified_at ? $t(`Verified`) : $t(`Unverified`) }}
                </b-badge>
              </div>
              <div class="card-text pt-25">
                {{ itemData.customer.formatted_mobile }}
                <b-badge
                  v-if="itemData.customer.formatted_mobile"
                  pill
                  :variant="`light-` + resolveBooleanStatusVariant(!!itemData.customer.mobile_verified_at)"
                >
                  {{ itemData.customer.mobile_verified_at ? $t(`Verified`) : $t(`Unverified`) }}
                </b-badge>
              </div>
            </div>
            <div v-else>
              <h6 class="mb-0">
                <b-badge
                  pill
                  variant="light-secondary"
                  style="width: 120px; height: 21px"
                >&nbsp;</b-badge>
              </h6>
              <small class="text-muted text-left">
                <b-badge
                  pill
                  variant="light-secondary"
                  class="mt-75 mr-1"
                  style="width: 100px; height: 16px;"
                >&nbsp;</b-badge>
              </small>
            </div>
          </div>
        </div>

        <!-- Order Basic Info -->
        <div class="d-flex align-items-center">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="HashIcon"
                  class="mr-75"
                />
                <!--                <span class="font-weight-bold">ID</span>-->
              </th>
              <td class="pb-50">
                <small>{{ itemData.id }}</small>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <!--                <span-->
                <!--                    v-t="`modules.orders._create.creation_date`"-->
                <!--                    class="font-weight-bold"-->
                <!--                />-->
              </th>
              <td>
                {{ formatDatetime(itemData.created_at) }}
              </td>
            </tr>
            <tr v-if="itemData.is_guest_order">
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
              </th>
              <td>
                {{ $t(`modules.orders._create.was_guest_order`) }}
              </td>
            </tr>
          </table>
        </div>

        <!-- Order Status -->
        <div class="d-flex align-items-center mt-2">
          <div
            v-if="false && isCourierControlsShipping"
            class="d-flex align-items-center mr-2"
          >
            <h5
              v-t="`modules.orders._create.order_status`"
              class="mb-0"
            />
            <div class="ml-1">

              <b-badge
                :variant="`light-${resolveOrderStatusVariant(itemData.status)}`"
              >
                {{ $t(`modules.orders.status.${itemData.status}`) }}
              </b-badge>

              <b-dropdown
                v-if="itemData.allowed_status_options.length"
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :text="$t(`modules.orders.status.${itemData.status}`)"
                :variant="resolveOrderStatusVariant(itemData.status)"
                :disabled="statusUpdateInProgress"
              >
                <b-dropdown-item
                  v-for="status in itemData.allowed_status_options"
                  :key="`dropdown-status-${status}`"
                  @click="$emit('status-update-requested', status)"
                >
                  {{ $t(`modules.orders.status.${status}`) }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                v-else
                id="dropdown-1"
                :variant="resolveOrderStatusVariant(itemData.status)"
              >{{ $t(`modules.orders.status.${itemData.status}`) }}</b-button>
            </div>
          </div>
          <div
            v-else
            class="d-flex align-items-center mr-2"
          >
            <h5
              v-t="`modules.orders._create.order_status`"
              class="mb-0"
            />
            <div class="ml-1">
              <b-dropdown
                v-if="itemData.allowed_status_options.length"
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :text="$t(`modules.orders.status.${itemData.status}`)"
                :variant="resolveOrderStatusVariant(itemData.status)"
                :disabled="statusUpdateInProgress"
              >
                <b-dropdown-item
                  v-for="status in itemData.allowed_status_options"
                  :key="`dropdown-status-${status}`"
                  @click="$emit('status-update-requested', status)"
                >
                  {{ $t(`modules.orders.status.${status}`) }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                v-else
                id="dropdown-1"
                :variant="resolveOrderStatusVariant(itemData.status)"
              >{{ $t(`modules.orders.status.${itemData.status}`) }}</b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        md="6"
      >
        <table
          v-if="itemData.shipping_address && itemData.shipping_address.address_id"
          class="mt-2 mt-xl-0 w-100"
        >
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span
                v-t="`modules.addresses.details.name`"
                class="font-weight-bold"
              />
            </th>
            <td class="pb-50">
              {{ itemData.shipping_address.name || '-' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span
                v-t="`modules.addresses.details.mobile`"
                class="font-weight-bold"
              />
            </th>
            <td>
              <span style="direction: ltr"><span class="d-inline-block">{{ itemData.shipping_address.formatted_mobile || '-' }}</span></span>
              <b-badge
                v-if="itemData.shipping_address.is_mobile_verified"
                pill
                :variant="`light-` + resolveBooleanStatusVariant(itemData.shipping_address.is_mobile_verified)"
              >
                {{ itemData.shipping_address.is_mobile_verified ? $t(`Verified`) : $t(`Unverified`) }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="GlobeIcon"
                class="mr-75"
              />
              <span
                v-t="`modules.addresses.s_c`"
                class="font-weight-bold"
              />
            </th>
            <td class="pb-50">
              {{ itemData.shipping_address.line_1 }}
              <br>
              {{ itemData.shipping_address.line_2 }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span
                v-t="`modules.addresses.details.country_city`"
                class="font-weight-bold"
              />
            </th>
            <td class="pb-50">
              {{ countryCityDetails }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapIcon"
                class="mr-75"
              />
              <span
                v-t="`modules.addresses.details.short_address`"
                class="font-weight-bold"
              />
            </th>
            <td class="pb-50">
              {{ itemData.shipping_address.short_address }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span
                v-t="`modules.addresses.details.geo_location`"
                class="font-weight-bold"
              />
            </th>
            <td class="pb-50">
              <a
                v-if="itemData.shipping_address.lat && itemData.shipping_address.long"
                target="_blank"
                :href="`https://www.google.com/maps/search/${itemData.shipping_address.lat},+${itemData.shipping_address.long}`"
              >
                <feather-icon
                  icon="ExternalLinkIcon"
                  class="mr-25"
                  size="14"
                />
                <span v-t="`modules.addresses.details.show_location`" />
              </a>
              <span v-else>-</span>
            </td>
          </tr>
        </table>
        <span v-else>

          <div class="m-2">
            <b-badge
              pill
              variant="light-secondary"
              style="width: 40%; height: 21px"
            >&nbsp;</b-badge>
          </div>
          <div class="m-2">
            <b-badge
              pill
              variant="light-secondary"
              style="width: 40%; height: 21px"
            >&nbsp;</b-badge>
          </div>
          <div class="m-2">
            <b-badge
              pill
              variant="light-secondary"
              style="width: 40%; height: 21px"
            >&nbsp;</b-badge>
          </div>
          <div class="m-2">
            <b-badge
              pill
              variant="light-secondary"
              style="width: 40%; height: 21px"
            >&nbsp;</b-badge>
          </div>

        </span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol, BBadge, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { avatarText, formatDate, formatDatetime } from '@core/utils/filter'
import useModelList from '@/views/models/common/useModelList'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BDropdown,
    BButton,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      default: Array,
    },
    errors: {
      type: Array,
      default: Array,
      required: false,
    },
    statusUpdateInProgress: {
      type: Boolean,
      default: false,
      required: false,
    },
    isCourierControlsShipping: {
      type: Boolean,
      default: false,
      required: false,
    },
    canCreateWaybill: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ['status-update-requested'],
  computed: {
    countryCityDetails() {
      /** @REF: src/views/models/orders/add/ModalAddShipment.vue */
      let value = ''
      if (this.itemData.shipping_address.city) {
        value += `${this.itemData.shipping_address?.city.name}, `
      } else if (this.itemData.shipping_address.city_name) {
        value += `${this.itemData.shipping_address?.city_name}, `
      }
      if (this.itemData.shipping_address.state) {
        value += `${this.itemData.shipping_address?.state.name}, `
      }
      if (this.itemData.shipping_address.country) {
        value += `${this.itemData.shipping_address?.country.name}, `
      }
      return value
    },
  },
  setup() {
    const {
      resolveBooleanStatusVariant,
      resolveBooleanImportantStatusVariant,
      resolveOrderStatusVariant,
    } = useModelList('customers', [])

    return {
      avatarText,
      resolveBooleanStatusVariant,
      resolveBooleanImportantStatusVariant,
      resolveOrderStatusVariant,
      formatDate,
      formatDatetime,
    }
  },
}
</script>

<style>

</style>
