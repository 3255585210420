// const BACKEND_HOST = 'http://api.test'
import { env } from '@core/utils/utils'

const BACKEND_HOST = env('VUE_APP_BACKEND_API_HOST', 'http://api.localhost')
const DASHBOARD_ENDPOINT = env('VUE_APP_DASHBOARD_API_ENDPOINT', 'dashboard/v1')
export default {
  BACKEND_HOST,
  DASHBOARD_ENDPOINT,

  // Endpoints
  csrfEndpoint: `${BACKEND_HOST}/sanctum/csrf-cookie`,
  loginEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/login`,
  registerEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/register`,
  executeRegistrationEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/register/continue`,
  sendOtpEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/mobile-otp`,
  validateEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/validate`,
  issueTokenEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/login`,
  issueDemoTokenEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/demo`,
  refreshTokenEndpoint: `${BACKEND_HOST}/api/refresh-token`,
  revokeTokenEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/logout`,
  logoutEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/logout`,
  forgotPasswordEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/forgot-password`,
  resetPasswordEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/reset-password`,
  homeEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/`,
  syncEndpoint: `${BACKEND_HOST}/${DASHBOARD_ENDPOINT}/sync`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
