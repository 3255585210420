<template>
  <div>
    <b-form-radio-group
      :id="$attrs.id"
      v-model="currentValue"
      class="mb-2"
      value-field="value"
      text-field="label"
      disabled-field="disabled"
      :state="errors.length > 0 ? false:null"
      v-bind="$attrs"
      :placeholder="$attrs.placeholder || `${$t('Select')} ${$attrs.title}`"
      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
      v-on="$listeners"
      @input="$emit('test')"
      @update:model="$emit('model')"
      @update:value="$emit('value')"
      @update:modelValue="$emit('modelValue')"
      @update:model-value="$emit('model-value')"
    />
  </div>
</template>

<script>
import { getLocale } from '@core/utils/utils'
import { BFormRadioGroup } from 'bootstrap-vue'

export default {
  components: {
    BFormRadioGroup,
  },
  props: {
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: null,
    },
    errors: {
      type: Array,
      default: Array,
    },
    reduce: {
      type: Function,
      default: o => o,
    },
  },
  data() {
    return {
      currentValue: this.value,
      locale: getLocale(),
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    },
  },
  setup(args, args2) {
    // console.log('On SelectInput setup args', args, args2)
  },
}
</script>

<style lang="scss">
</style>
