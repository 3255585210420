<template>
  <b-form @submit.prevent="$emit('submit')">
    <i-input
      v-for="(input, inputKey) in app.settings_schema"
      :key="inputKey"
      v-model="settings[inputKey]"
      :title="input.label[$i18n.locale]"
      :type="getSettingInputType(input.type)"
      :placeholder="input.placeholder"
      :translatable="input.localizable || false"
      :validation-rules="filterValidationRules(input.rules)"
      v-bind="getSettingInputBinding(input)"
    />
  </b-form>
</template>

<script>
import {
  BForm,
} from 'bootstrap-vue'
import { getLocale } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
  },
  directives: {
    Ripple,
  },
  props: {
    app: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  created() {
    //
  },
  methods: {
    getLocale,
    getSettingInputType: type => {
      if (type === 'file') {
        return 'file'
      }
      return type
    },
    getSettingInputBinding: input => {
      const bindings = {}
      if (Object.hasOwn(input, 'class')) {
        bindings['input-bindings'] = {
          class: input.class,
        }
      }
      if (Object.hasOwn(input, 'style')) {
        bindings['input-bindings'] = {
          class: input.style,
        }
      }
      if (input.type === 'file') {
        return {
          ...bindings,
          size: 'lg',
          autoUpload: false,
        }
      }
      if (input.type === 'select') {
        return {
          ...bindings,
          options: input.options,
          reduce: o => o.value,
        }
      }
      return bindings
    },
  },
  setup(props, { emit }) {
    const filterValidationRules = rules => rules.filter(rule => ['required'].indexOf(rule) >= 0)
    return {
      filterValidationRules,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
.modal-body label {
  font-size: unset;
}
</style>
