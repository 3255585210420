<template>
  <div class="iframe-container">
    <shipping-methods-list-loader
      v-if="isLoading"
    />

    <template v-else>
      <div class="iframe-wrapper">
        <iframe
          title="External App"
          :src="iframeSrc"
          allow="clipboard-read; clipboard-write;"
          context="Main"
          class="embed-iframe"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {
  onUnmounted, ref, watch,
} from 'vue'
import ShippingMethodsListLoader from '@/views/loaders/ShippingMethodsListLoader.vue'
import store from '@/store'
import courierStoreModule from '@/views/models/couriers/courierStoreModule'
import router from '@/router'
import {handleBadRequestError, handleValidationError, toast} from "@core/utils/utils";
import {useUtils as useI18nUtils} from "@core/libs/i18n";

export default {
  components: {
    ShippingMethodsListLoader,
    // SFC
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'couriers'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, courierStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const isLoading = ref(false)
    const iframe = ref(null)
    const iframeSrc = ref(null)
    const items = ref([])
    const suggestions = ref([])
    const cities = ref([])
    const settings = ref({})
    const { t } = useI18nUtils()

    watch(iframe, () => {
      if (!iframe.value) {
        return
      }
      iframe.value.onload = () => {
        console.log('loaded')
        // set the height of the iframe as the height of the iframe content
        iframe.value.style.height = `${iframe.value.contentWindow.document.body.scrollHeight}px`

        // set the width of the iframe as the width of the iframe content
        iframe.value.style.width = `${iframe.value.contentWindow.document.body.scrollWidth}px`
      }
    })

    const getActionData = () => {
      // ctx.refs.preview.reset()
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/fetchPickups`, router.currentRoute.params.courier)
        .then(response => {
          isLoading.value = false
          if (response.type !== 'embedded') {
            router.push({ name: 'error-404' })
            return
          }

          iframeSrc.value = response.url
        })
        .catch(e => {
          if (!handleValidationError(e)) {
            if (handleBadRequestError(e)) {
              router.push({ name: 'shippingSettings' })
            } else {
              const title = typeof (t) === 'function' ? t('message.operation_failed') : 'Error'
              toast('danger', title, '', 'AlertTriangleIcon')
            }
          }
        })
    }
    getActionData()

    return {
      isLoading,
      iframe,
      iframeSrc,
      items,
      suggestions,
      cities,
      settings,

      // Actions
      getActionData,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/shipping-management';
div.ecommerce-application div.content-body {
  position: static;
}
.iframe-container {
  position: static;
}
.iframe-wrapper {
  display: flex; flex-direction: column; position: absolute; inset: 0px; width: 100%;padding-top: 165px;
  height: 100%;
}
.embed-iframe {
  position: relative; border: medium; width: 100%; flex: 1 1 0%; display: flex;
}
</style>
