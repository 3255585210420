<template>
  <b-card-actions
    :title="$t('modules.dynamic_fields._cards.product_fields.title')"
    action-collapse
  >
    <b-card-sub-title>
      {{ $t('modules.dynamic_fields._cards.product_fields.description') }}
    </b-card-sub-title>
    <!-- Options -->
    <b-card-body class="invoice-padding form-item-section">
      <b-overlay
        :show="isLoading"
        rounded="sm"
        variant="secondary"
        opacity="0.75"
      >
        <div
          ref="dynamicFieldsForm"
          class="repeater-form"
        >
          <b-row
            ref="row"
            class="pb-2"
          >
            <!-- Item Form -->
            <!-- ? This will be in loop => So consider below markup for single item -->
            <b-col
              v-if="dynamicFieldsListData.length"
              cols="12"
            >

              <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
              <div class="d-none d-lg-flex pb-50">
                <b-row class="flex-grow-1 px-1">
                  <!-- Single Item Form Headers -->
                  <b-col
                    cols="12"
                    lg="5"
                  >
                    {{ $t('modules.dynamic_fields.inputs.field_label') }}
                  </b-col>
                  <b-col
                    cols="12"
                    lg="4"
                  >
                    {{ $t('modules.dynamic_fields.inputs.field_type') }}
                  </b-col>
                  <b-col
                    cols="12"
                    lg="2"
                  >
                    {{ $t('modules.dynamic_fields.inputs.field_required') }}
                  </b-col>
                </b-row>
                <div class="form-item-action-col" />
              </div>

              <!-- Form Input Fields OR content inside bordered area  -->
              <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
              <div
                v-for="(item, index) in dynamicFieldsListData"
                :key="index"
                class="d-flex border rounded pt-2"
              >
                <b-row class="flex-grow-1 pt-2 pl-50">
                  <!-- Single Item Form Headers -->
                  <b-col
                    cols="12"
                    lg="5"
                  >
                    <label class="d-inline d-lg-none">{{ $t('modules.dynamic_fields.inputs.field_label') }}</label>
                    <i-input
                      v-model="item.label"
                      :errors="validationErrors.label"
                      type="text"
                      title="modules.dynamic_fields.inputs.field_label"
                      :no-label="true"
                      class="mb-2"
                      required
                      :translatable="true"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="4"
                  >
                    <label class="d-inline d-lg-none">{{ $t('modules.dynamic_fields.inputs.field_type') }}</label>
                    <i-input
                      v-model="item.type"
                      :errors="validationErrors.options"
                      type="select"
                      title="modules.dynamic_fields.inputs.field_type"
                      :no-label="true"
                      :options="fieldTypeOptions"
                      key-attribute="id"
                      :reduce="o => o.id"
                      :label="`name_${locale}`"
                      class="mb-2 item-selector-title"
                      required
                      :input-bindings="{
                        noEmptySlot: true,
                      }"
                      @input="item.values = []"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="2"
                  >
                    <label class="d-inline d-lg-none">{{ $t('modules.dynamic_fields.inputs.field_required') }}</label>
                    <i-input
                      v-model="item.required"
                      :errors="validationErrors.required"
                      type="switch"
                      title="modules.dynamic_fields.inputs.field_required"
                      :no-label="true"
                      class="mb-2"
                      required
                    />
                  </b-col>
                </b-row>
                <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                  <feather-icon
                    size="16"
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="removeOption(index)"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-button
          size="sm"
          variant="primary"
          @click="addNewOption"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t('modules.dynamic_fields.actions.add_field') }}
        </b-button>
      </b-overlay>
    </b-card-body>
  </b-card-actions>
</template>

<script>

import {
  BButton,
  BCardBody, BCardSubTitle,
  BCol, BOverlay,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import {
  computed, onMounted, onUnmounted, ref, watch,
} from 'vue'
import { getLocale } from '@core/utils/utils'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import store from '@/store'
import dynamicFieldStoreModule from '@/views/models/dynamic_fields/dynamicFieldStoreModule'
import useModelUpdate from '@/views/models/common/useModelUpdate'

export default {
  components: {
    BOverlay,
    BCardSubTitle,
    BCardActions,
    BRow,
    BCol,
    BCardBody,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      default: Object,
    },
    options: {
      type: Array,
      default: Array,
    },
    products: {
      type: Array,
      default: Array,
    },
    entityType: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: false,
      default: null,
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  methods: { getLocale },
  setup(props, { emit }) {
    const STORE_MODULE_NAME = dynamicFieldStoreModule.name

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, dynamicFieldStoreModule)
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, dynamicFieldStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const locale = getLocale()
    const blankDynamicField = {
      label: {
        en: null,
        ar: null,
      },
      type: 'text',
      required: false,
    }

    const dynamicFieldsListData = ref([])
    const originalRecordsData = ref([])
    // eslint-disable-next-line no-unused-vars
    const dynamicFieldsForm = ref()

    const fieldTypeOptions = [
      {
        id: 'text',
        name_en: 'Text',
        name_ar: 'نص قصير',
        type: 'text',
      },
      {
        id: 'textarea',
        name_en: 'Textarea',
        name_ar: 'نص طويل',
        type: 'textarea',
      },
      {
        id: 'number',
        name_en: 'Number',
        name_ar: 'رقم',
        type: 'number',
      },
      {
        id: 'email',
        name_en: 'Email',
        name_ar: 'بريد إلكتروني',
        type: 'email',
      },
      // {
      //   id: 'tel',
      //   name_en: 'Phone Number',
      //   name_ar: 'رقم هاتف',
      //   type: 'tel',
      // },
    ]

    // Changes tracking
    const hasChanges = computed(() => JSON.stringify(dynamicFieldsListData.value) !== JSON.stringify(originalRecordsData.value))
    watch(hasChanges, newValue => { emit('has-changes', newValue) })

    const reflectLocalUpdatesToParent = () => {
      // emit('update:options', dynamicFieldsListData.value)
    }

    const reflectParentUpdatesToLocal = newValue => {
      // dynamicFieldsListData.value = newValue.options
    }

    const updateItemForm = (index, val) => {
      //
    }

    const addNewOption = () => {
      const newOption = JSON.parse(JSON.stringify(blankDynamicField))
      newOption.id = parseInt(Math.random() * 10e9, 10)
      dynamicFieldsListData.value.push(newOption)

      reflectLocalUpdatesToParent()
    }
    const removeOption = index => {
      dynamicFieldsListData.value.splice(index, 1)
    }
    const initTrHeight = () => {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    }

    const validationErrors = ref({})

    const dynamicFieldsRequestPayload = ref({
      entity_type: props.entityType,
      entity_id: props.entityId,
      fields: [],
    })
    const refs = ref({})
    const { performSave } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      dynamicFieldsRequestPayload,
      () => {
      },
      null,
      null,
      () => {
      },
    )

    const setEntityId = entityId => {
      dynamicFieldsRequestPayload.value.entity_id = entityId
    }

    const save = (...args) => {
      dynamicFieldsRequestPayload.value.fields = dynamicFieldsListData.value
      return performSave(...args)
    }

    const isLoading = ref(false)
    const fetchRecords = () => {
      if (!props.entityId) {
        return
      }
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/fetchAll`, {
        entity_type: props.entityType,
        entity_id: props.entityId,
      })
        .then(response => {
          dynamicFieldsListData.value = response.data.data
          originalRecordsData.value = JSON.parse(JSON.stringify(dynamicFieldsListData.value))
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    fetchRecords()

    return {
      isLoading,
      refs,
      locale,
      dynamicFieldsListData,
      fieldTypeOptions,
      addNewOption,
      removeOption,
      reflectLocalUpdatesToParent,
      reflectParentUpdatesToLocal,
      initTrHeight,
      updateItemForm,
      validationErrors,
      hasChanges,
      setEntityId,
      save,
      fetchRecords,
    }
  },
}
</script>

<style lang="scss" scoped>

/* Product Options */
.product-color-options {
  margin-top: 1.5rem;
  margin-bottom: 1.2rem;
}

.product-color-options {
  .color-option {
    border: 1px solid transparent;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    padding: 3px;

    .filloption {
      height: 18px;
      width: 18px;
      border-radius: 50%;
    }
  }
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}
</style>
