import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import axios from '@/libs/axios'
import dynamicFieldModel from '@/views/models/dynamic_fields/dynamicFieldModel'

const {
  RESOURCE_ENDPOINT,
  deleteOne,
} = useModelStoreModule(dynamicFieldModel)

const fetchAll = (ctx, queryParams) => new Promise((resolve, reject) => {
  axios
    .get(`${RESOURCE_ENDPOINT}/${queryParams.entity_type}/${queryParams.entity_id}`, { params: { page: queryParams.page } })
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const create = (ctx, itemData) => new Promise((resolve, reject) => {
  axios
    .post(`${RESOURCE_ENDPOINT}/${itemData.entity_type}/${itemData.entity_id}`, dynamicFieldModel.toArray(itemData))
    .then(response => {
      if (response/* && ((response.status === 201)) */) {
        return resolve(dynamicFieldModel.fromArray(response.data.data))
      }
      return reject(new Error('Operation Failed!'))
    })
    .catch(error => {
      if (error?.response?.status === 422) {
        error.response.data.errors = dynamicFieldModel.resolveValidationErrors(error.response.data.errors)
      }
      return reject(error)
    })
})

export default {
  name: 'dynamic_fields',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll,
    create,
    deleteOne,
  },
}
