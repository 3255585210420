<template>
  <div>
    <b-row
      ref="form"
      class="repeater-form"
    >
      <b-col
        md="12"
        class="mb-1"
      >
        <h5
          v-t="'modules.waybills._create.tabs.package_details'"
          class="mb-0"
        />
        <small
          v-t="'modules.waybills._create.tabs.package_details:description'"
          class="text-muted mb-1"
        />
        <b-alert
          v-if="!value.is_all_items_have_weight"
          variant="warning"
          class="mt-1"
          show
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="AlertTriangleIcon"
            />
            <span
              v-t="`modules.waybills._create._messages.some_products_missing_weight`"
              class="ml-25"
            />
          </div>
        </b-alert>
      </b-col>
      <template>
        <b-col
          md="6"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t(`modules.waybills._create.inputs.package_weight`)"
            rules="required|min:0"
          >
            <b-form-group
              :label="$t(`modules.waybills._create.inputs.package_weight`)"
              label-for="package_weight"
            >
              <b-input-group
                append="KG"
                :class="{'is-invalid': errors && errors.length}"
              >
                <b-form-input
                  id="package_weight"
                  v-model="value.package_weight"
                  type="number"
                  placeholder="1"
                  step="1"
                  min="0"
                  required
                  :state="errors.length > 0 ? false:null"
                />
              </b-input-group>
              <!--                      <small class="text-danger">{{ errors[0] }}</small>-->
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="6"
        >
          <i-input
            v-model="value.package_size"
            :errors="validationErrors.package_size"
            type="select"
            title="modules.waybills._create.inputs.package_size"
            description="modules.waybills._create.inputs.package_size:description"
            :options="courierSettingOptions.package_sizes"
            :clearable="true"
            required
            label="title"
            :disabled="isLoadingCourierSettingOptions"
          />
        </b-col>
      </template>

      <b-col
        cols="12"
        class="mb-2 ecommerce-application"
      >
        <hr
          class="my-2"
        >
        <shipment-items
          :items="value.items"
        />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider } from 'vee-validate'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getLocaleCurrency } from '@core/utils/utils'
import ShipmentItems from '@/views/models/waybills/add/ShipmentItems.vue'

export default {
  components: {
    ShipmentItems,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BAlert,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    value: {
      type: Object,
      required: true,
    },
    isLoadingCourierSettingOptions: {
      type: Boolean,
      default: false,
    },
    courierSettingOptions: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getLocaleCurrency,
  },
  setup(props, ctx) {
    return {
      isAutoRatesFeatureAvailable: false,
      validationErrors: {},
      item: {},
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  transition: .35s height;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 600px;
}
</style>

<style lang="scss">
//[dir] .custom-control-primary .custom-control-input ~ .custom-control-label::before {
//  border-color: #00cfe8;
//  background-color: #00cfe8;
//}
//.custom-switch .custom-control-label .switch-icon-right {
//  color: #fff;
//}

[dir="ltr"] .form-control.is-invalid[type=number] {
  padding: 0.438rem 1rem;
}
[dir] .form-control.is-invalid[type=number] {
  background: unset;
}
</style>
