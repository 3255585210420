<template>

  <div class="checkout-items">
    <div
      class="text-right mb-0"
    >
      <b-button
        v-b-modal.modal-add-new
        variant="primary"
        class="btn-cart"
        @click="modelItemId = null; selectCourierForCreation = null"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span v-t="`modules.shipping_methods.actions.add_new`" />
      </b-button>
    </div>
    <p
      v-t="`modules.shipping_methods._list.active_methods`"
    />
    <shipping-method-item
      v-for="item in items"
      :ref="`item_${item.code}`"
      :key="`item_${item.code}`"
      :item="item"
      @click-edit="itemId => {modelItemId=itemId; selectCourierForCreation = null}"
      @click-action="action => onActionClick(item, action)"
      @item-update="$emit('item-update')"
    />
    <hr>
    <p
      v-t="`modules.shipping_methods._list.add_more_shipping_methods`"
      class="pt-1"
    />
    <shipping-method-item
      v-for="item in suggestions"
      :key="`suggestion_${item.code}`"
      :item="item"
      @click-add="onAddMethodClick"
    />

    <wizard-modal-add-new-shipping-method
      :options="suggestions"
      :cities="cities"
      :item-id.sync="modelItemId"
      :preselected-courier-code="selectCourierForCreation"
      @onSubmit="$emit('item-update')"
    />
  </div>

</template>

<script>
import {
  BButton, VBToggle, VBModal,
} from 'bootstrap-vue'
import { ref } from 'vue'
import ShippingMethodItem from '@/views/models/shipping_methods/list/ShippingMethodItem.vue'
import WizardModalAddNewShippingMethod from '@/views/models/shipping_methods/add/WizardModalAddNewShippingMethod.vue'
import router from '@/router'

export default {
  components: {
    ShippingMethodItem,
    BButton,
    WizardModalAddNewShippingMethod,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array,
      required: false,
      default: Array,
    },
    suggestions: {
      type: Array,
      required: false,
      default: Array,
    },
    cities: {
      type: Array,
      required: false,
      default: Array,
    },
  },
  setup(props, ctx) {
    const isAddNewSidebarActive = ref(false)
    const modelItemId = ref(null)
    const selectCourierForCreation = ref(null)

    const onAddMethodClick = courierCode => {
      modelItemId.value = null
      selectCourierForCreation.value = courierCode
      isAddNewSidebarActive.value = true
    }

    const onActionClick = (shippingMethod, action) => {
      router.push({ name: 'courierAction', params: { courier: shippingMethod.courierCode, action: action.code } })
    }
    return {
      onAddMethodClick,
      onActionClick,
      // Sidebar
      isAddNewSidebarActive,
      modelItemId,
      selectCourierForCreation,
    }
  },
}
</script>

<style type="text/css">
// Disable hover effect of shipping methods items
[dir] .ecommerce-application .ecommerce-card:hover {
  transform: unset !important;
}
</style>
