<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    variant="secondary"
    opacity="0.75"
  >
    <shipment-card
      v-for="shipment in shipments"
      :key="`shipment-${shipment.id}`"
      :shipment="shipment"
      @click-action="action => onClickAction(action, shipment)"
    />

    <!-- modal update shipment -->
    <modal-update-shipment
      v-if="activeShipment"
      :shipment="activeShipment"
      :is-visible="isShipmentModalVisible"
      class="ltr text-left"
      @update:is-visible="v => isShipmentModalVisible = v"
      @hidden="isShipmentModalVisible = false"
      @submitted="fetchItems"
    />

    <!-- modal print waybill -->
    <b-modal
      id="modal-print-waybill"
      ref="modalPrintWaybill"
      hide-footer
      centered
      size="xl"
      :title="$t('Print Waybill')"
    >
      <b-aspect aspect="16:9">
        <iframe
          id="waybillIframe"
          :src="shipmentPrintWaybillUrl"
          height="100%"
          width="100%"
        />
      </b-aspect>

    </b-modal>
  </b-overlay>
</template>

<script>
import { formatDate, formatDatetime } from '@core/utils/filter'
import {handleBadRequestError, handleValidationError, storagePath, toast} from '@core/utils/utils'
import { resolveShipmentStatusVariant } from '@core/utils/constants'
import ShipmentCard from '@/views/models/orders/add/ShipmentCard.vue'
import { onUnmounted, ref } from 'vue'
import store from '@/store'
import shipmentStoreModule from '@/views/models/orders/shipmentStoreModule'
import { BAspect, BModal, BOverlay } from 'bootstrap-vue'
import ModalUpdateShipment from '@/views/models/orders/add/ModalUpdateShipment.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BAspect,
    BModal,
    ModalUpdateShipment,
    BOverlay,
    ShipmentCard,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  methods: { resolveShipmentStatusVariant, formatDatetime, storagePath },
  setup(props, ctx) {
    const SHIPMENTS_STORE_MODULE_NAME = 'shipments'

    // Register module
    if (!store.hasModule(SHIPMENTS_STORE_MODULE_NAME)) store.registerModule(SHIPMENTS_STORE_MODULE_NAME, shipmentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SHIPMENTS_STORE_MODULE_NAME)) store.unregisterModule(SHIPMENTS_STORE_MODULE_NAME)
    })

    const ImagePlaceholder = require('@/assets/images/pages/eCommerce/placeholder.webp')

    const modalPrintWaybill = ref(null)
    const shipmentPrintWaybillUrl = ref(null)
    const shipments = ref([])
    const activeShipment = ref(null)
    const isShipmentModalVisible = ref(false)

    const isLoading = ref(false)
    const fetchItems = () => {
      isLoading.value = true
      store.dispatch(`${SHIPMENTS_STORE_MODULE_NAME}/fetchAll`, {
        order_id: props.orderId,
      })
        .then(response => {
          shipments.value = response.data.data
        })
        .finally(() => {
          isLoading.value = false
        })
    }
    fetchItems()

    const { t } = useI18nUtils()

    const onClickAction = (actionCode, shipment) => {
      switch (actionCode) {
        case 'change_tracking_info':
          activeShipment.value = shipment
          isShipmentModalVisible.value = true
          break
        case 'print_waybill':
          store.dispatch(`${SHIPMENTS_STORE_MODULE_NAME}/printWaybill`, shipment.id).then(response => {
            if (response.type === 'download') {
              try {
                toast('info', t('modules.waybills._create._messages.waybill_download_message'), null, 'DownloadIcon')
              } catch (e) {
                //
              }
              window.location.href = response.url
            } else {
              shipmentPrintWaybillUrl.value = response.url
              modalPrintWaybill.value.show()
            }
          })
          break
        case 'pickup':
        case 'pickups':
          router.push({ name: 'courierAction', params: { courier: shipment.courier.code, action: 'pickups' } })
          break
        case 'void':
          isLoading.value = true
          store.dispatch(`${SHIPMENTS_STORE_MODULE_NAME}/cancelWaybill`, shipment.id).then(() => {
            fetchItems()
          }).catch(e => {
            if (!handleValidationError(e)) {
              if (!handleBadRequestError(e)) {
                const title = typeof (t) === 'function' ? t('message.operation_failed') : 'Error'
                toast('danger', title, '', 'AlertTriangleIcon')
              }
            }
          }).finally(() => {
            isLoading.value = false
          })
          break
        default:
          console.log(actionCode)
          toast('danger', 'This action not supported')
      }
    }

    return {
      isLoading,
      shipments,
      activeShipment,
      isShipmentModalVisible,
      modalPrintWaybill,
      shipmentPrintWaybillUrl,
      onClickAction,
      fetchItems,

      // UI
      ImagePlaceholder,

      // Filter
      formatDate,
    }
  },
}
</script>

<style lang="css">
[dir] input[type=url] {
  text-align: left;
  direction: ltr;
}
</style>
