<template>
  <b-list-group
    class="d-flex"
    :class="{
      'flex-column': mode === 'vertical',
      'flex-row': mode === 'horizontal',
    }"
  >
    <b-list-group-item
      v-for="option in options"
      :key="option.code"
      :active="selectedOption === option[keyAttribute]"
      class="cursor-pointer position-relative rounded"
      :class="{
        'align-items-start flex-column': mode === 'vertical',
        'p-2 flex-grow-1 flex-shrink-1': mode === 'horizontal',
      }"
      variant="dark"
      @click="$emit('input', selectedOption = option[keyAttribute])"
    >
      <div class="d-flex w-100 justify-content-between">
        <h5
          class="m-1 text-black"
          :class="{
            'text-black': selectedOption !== option[keyAttribute],
            'text-white': selectedOption === option[keyAttribute]
          }"
        >
          <template v-if="labelPosition === 'before'">
            {{ option[labelAttribute] }}
          </template>

          <component
            :is="mode === 'horizontal' ? 'div' : 'span'"
            :class="{
              'text-center': mode === 'horizontal',
            }"
          >
            <img
              v-if="option.logo"
              :src="option.logo"
              :alt="option[labelAttribute]"
              :class="{
                'pl-3': mode === 'vertical',
              }"
              height="35"
              width="35"
            >
            <feather-icon
              v-else-if="option.icon"
              :icon="option.icon"
              size="35"
            />
          </component>

          <span
            v-if="labelPosition === 'after'"
            :class="{
              'mt-50 d-block text-center': mode === 'horizontal',
            }"
          >
            {{ option[labelAttribute] }}
          </span>
        </h5>
        <small
          v-if="selectedOption === option[keyAttribute]"
          class="text-secondary position-absolute top-left"
          style="left: 0.5rem;  top: 0.5rem;"
        >
          <feather-icon
            icon="CheckIcon"
          />
        </small>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getLocaleCurrency } from '@core/utils/utils'
import { ref } from 'vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  emits: ['input'],
  props: {
    options: {
      type: Array,
      required: true,
    },
    keyAttribute: {
      type: String,
      default: 'key',
    },
    labelAttribute: {
      type: String,
      default: 'label',
    },
    labelPosition: {
      type: String,
      default: 'after',
    },
    mode: {
      type: String,
      default: 'horizontal',
    },
    value: {
      type: String,
      required: false,
    },
  },
  methods: {
    getLocaleCurrency,
  },
  setup(props, ctx) {
    const selectedOption = ref(props.options.filter(o => o[props.keyAttribute] === props.value)[0] || null)

    // TODO: Migrate SelectNetworkTab.vue to use this component

    return {
      validationErrors: {},
      selectedOption,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
