<template>
  <validation-observer ref="refForm">
    <b-form
      class="auth-register-form mt-2"
      @submit.prevent="validateStep"
    >
      <div class="position-relative">
        <b-card-title class="mb-1">
          {{ $t('auth.ready.cards.merchant_experience.title') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('auth.ready.cards.merchant_experience.subtitle') }}
        </b-card-text>

        <template class="mt-2 mb-2">
          <i-input
              id="has_ecommerce_experience"
              v-model="stepData.has_ecommerce_experience"
              :errors="validationErrorsArray('has_ecommerce_experience')"
              type="radio"
              title="auth.ready.cards.merchant_experience.inputs.has_ecommerce_experience"
              :options="booleanOptions"
              :reduce="option => option.value"
              required
          />

          <Transition name="fade-bottom">
            <i-input
                v-if="stepData.has_ecommerce_experience === 'true'"
                id="has_previous_online_store"
                v-model="stepData.has_previous_online_store"
                :errors="validationErrorsArray('has_previous_online_store')"
                type="radio"
                title="auth.ready.cards.merchant_experience.inputs.has_previous_online_store"
                :options="booleanOptions"
                :reduce="option => option.value"
                required
            />
          </Transition>

          <Transition name="fade-bottom">
            <i-input
                v-if="stepData.has_ecommerce_experience === 'true' && stepData.has_previous_online_store === 'true'"
                id="previous_online_store_url"
                v-model="stepData.previous_online_store_url"
                :errors="validationErrorsArray('previous_online_store_url')"
                type="text"
                placeholder="https://xxxxxxxxx"
                class="ltr text-left"
                title="auth.ready.cards.merchant_experience.inputs.previous_online_store_url"
            />
          </Transition>

          <Transition name="fade-bottom">
            <i-input
                v-if="stepData.has_ecommerce_experience === 'true' && stepData.has_previous_online_store === 'true' && stepData.previous_online_store_url"
                id="want_to_migrate"
                v-model="stepData.want_to_migrate"
                :errors="validationErrorsArray('want_to_migrate')"
                type="radio"
                title="auth.ready.cards.merchant_experience.inputs.want_to_migrate"
                :options="booleanOptions"
                :reduce="option => option.value"
                required
            />
          </Transition>

        </template>

        <!-- submit button -->
        <b-button
          variant="primary"
          block
          type="submit"
          class="mt-2"
        >
          {{ $t(isFinalStep ? 'Save' : 'Next') }}
        </b-button>
        <b-button
          v-if="!isFirstStep"
          variant="light"
          block
          type="button"
          @click="$emit('back')"
        >
          {{ $t('Back') }}
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {
  getLanguagesList,
  platformName, platformUrl, toast, trackEvent,
} from '@core/utils/utils'
import {
  BButton, BCardText, BCardTitle, BForm, BFormCheckbox, BTab, BTabs,
} from 'bootstrap-vue'
import { ref } from 'vue'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import useJwt from "@/auth/jwt/useJwt";
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import AppSelectionBtns from "@core/components/app-selection-btns/AppSelectionBtns.vue";

export default {
  components: {
    BFormCheckbox,
    AppSelectionBtns,
    BForm,
    BCardText,
    BCardTitle,
    BTabs,
    BTab,
    BButton,
    // BSV
    // validations
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  emits: ['submit', 'back'],
  props: {
    isFirstStep: {
      type: Boolean,
      default: false,
    },
    isFinalStep: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
      default: () => ({}),
    },
    industryOptions: {
      type: Array,
      default: () => [],
    },
    countryOptions: {
      type: Array,
      default: () => [],
    },
    currencyOptions: {
      type: Array,
      default: () => [],
    },
    remoteErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    trackEvent('InitiateReadinessStep2')
    // Set value of refs
    this.refs.value = this.$refs
  },
  methods: {
    platformUrl,
    platformName,
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const refForm = ref(null)
    const refs = ref({})
    const stepData = ref({
      step: 3,
      has_ecommerce_experience: props.currentData.has_ecommerce_experience || null,
      has_previous_online_store: props.currentData.has_previous_online_store || null,
      previous_online_store_url: props.currentData.previous_online_store_url || null,
      want_to_migrate: props.currentData.want_to_migrate || null,
    })

    const validateStep = () => {
      refForm.value.validateWithInfo().then(({ isValid, errors, fields }) => {
        if (isValid) {
          emit('submit', stepData.value)
        } else {
          toast('warning', t('message.fill_required_fields'))

          const failedFields = Object.keys(fields).filter(key => fields[key].failed)
          const { focusOnTabsOfErrors } = useModelUpdate(null, null, null, null)
          focusOnTabsOfErrors(failedFields, refs)
        }
      })
    }

    const validationError = (attr) => {
      if (attr === 'name') {
        return validationError('name.ar') || validationError('name.en')
      }
      return props.remoteErrors[`${attr}`]?.[0] || null
    }
    const validationErrorsArray = (attr) => {
      const error = validationError(attr)
      return error ? [error] : []
    }


    const languageOptions = [
        ... getLanguagesList().map(language => ({'label': t('auth.ready.cards.store_locale.inputs.store_languages:options.' + language.slug), 'value': language.slug})),
      {'label': t('auth.ready.cards.store_locale.inputs.store_languages:options.both'), 'value': 'both'}
    ]

    const booleanOptions = [{label: t('Yes'), value: 'true'}, {label: t('No'), value: 'false'}]

    return {
      refForm,
      refs,
      stepData,
      languageOptions,
      booleanOptions,
      validateStep,
      validationError,
      validationErrorsArray,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.vs__dropdown-menu {
  max-height: 200px !important;
}

.ready-submit-btn {
  bottom: 22px;
  z-index: 99;
}
</style>
