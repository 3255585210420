<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-modal
      id="install-app"
      ref="installationModalRef"
      cancel-variant="outline-secondary"
      :ok-title="$t(app.is_installed ? `modules.apps.actions.edit` : `modules.apps.actions.${app.submit_action}`)"
      :cancel-title="$t('Cancel')"
      centered
      :title="$t(app.is_installed ? `modules.apps.actions.edit_app` : `modules.apps.actions.${app.submit_action}_app`)"
      :cancel-disabled="isSubmitLoading"
      :ok-disabled="isSubmitLoading"
      @show="resetForm"
      @hidden="resetForm"
      @ok="e => {e.preventDefault(); handleSubmit(onSubmitHandler)}"
    >
      <b-overlay
        :show="isSubmitLoading"
        rounded="sm"
        variant="secondary"
        opacity="0.75"
      >
        <AppSettingsForm
          :app="app"
          :settings="settings"
          @update:settings="v => settings = v"
          @submit="handleSubmit(onSubmitHandler)"
        />
      </b-overlay>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import { nextTick, onUnmounted, ref } from 'vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { catchException, getLocale, toast } from '@core/utils/utils'
import appStoreModule from '@/views/models/apps/appStoreModule'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import AppSettingsForm from '@/views/models/apps/install/AppSettingsForm.vue'

export default {
  components: {
    AppSettingsForm,
    ValidationObserver,
    BOverlay,
    BModal,
  },
  directives: {
    Ripple,
  },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  created() {
    //
  },
  methods: {
    getLocale,
  },
  setup(props, { emit }) {
    const STORE_MODULE_NAME = 'apps'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, appStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const settings = ref({})
    const isLoading = ref(false)
    const isSubmitLoading = ref(false)
    const installationModalRef = ref(false)
    // Use toast
    const { t } = useI18nUtils()

    const useApp = () => {
      isSubmitLoading.value = true
      store
        .dispatch(props.app.submit_action === 'install' ? `${STORE_MODULE_NAME}/create` : `${STORE_MODULE_NAME}/update`, { id: props.app.id, settings: settings.value })
        .then(response => {
          if (props.app.submit_action === 'install') {
            emit('update:app', response)
            toast(
              'success',
              t('message.operation_success'),
              null,
              'CheckCircleIcon',
            )
          } else if (response?.message) {
            toast(
              'success',
              null,
                  response?.message,
                  'CheckCircleIcon',
            )
          }

          nextTick(() => {
            installationModalRef.value.toggle('#toggle-btn')
          })
        })
        .catch(e => {
          if (e?.response?.status === 422) {
            toast(
              'warning',
              e.response.data.message,
              null,
              'AlertTriangleIcon',
            )
            return
          }
          catchException(e)
          toast(
            'danger',
            t('message.operation_failed'),
              e?.response?.data?.message || null,
              'AlertTriangleIcon',
          )
        })
        .finally(() => {
          isSubmitLoading.value = false
        })
    }

    const resetItemData = () => {
      settings.value = JSON.parse(JSON.stringify(props.app.settings))
    }

    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetItemData)

    return {
      settings,
      isLoading,
      isSubmitLoading,
      onSubmitHandler: useApp,
      installationModalRef,
      refFormObserver,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
.modal-body label {
  font-size: unset;
}
</style>
