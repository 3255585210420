import { useUtils as useI18nUtils } from '@core/libs/i18n'

const { t } = useI18nUtils()
export const orderStatusOptions = [
  { label: t('modules.orders.status.DRFT'), value: 'DRFT', color: 'rgba(75,75,75, 0.8)' },
  { label: t('modules.orders.status.PEND'), value: 'PEND', color: 'rgba(255,159,67,0.8)' },
  { label: t('modules.orders.status.PRCS'), value: 'PRCS', color: 'rgba(255,202,67, 0.8)' },
  { label: t('modules.orders.status.RDYS'), value: 'RDYS', color: 'rgba(0,207,232, 0.8)' },
  { label: t('modules.orders.status.SHPD'), value: 'SHPD', color: 'rgba(0,201,156, 0.8)' },
  { label: t('modules.orders.status.SDLV'), value: 'SDLV', color: 'rgba(0,201,156, 0.8)' },
  { label: t('modules.orders.status.RDYP'), value: 'RDYP', color: 'rgba(0,128,232, 0.8)' },
  { label: t('modules.orders.status.DLVR'), value: 'DLVR', color: 'rgba(40,199,111, 0.8)' },
  { label: t('modules.orders.status.RFSD'), value: 'RFSD', color: 'rgba(234,84,85, 0.8)' },
  { label: t('modules.orders.status.CNCL'), value: 'CNCL', color: 'rgba(190,67,68, 0.8)' },
  { label: t('modules.orders.status.RTRN'), value: 'RTRN', color: 'rgba(130,134,139, 0.8)' },
]

export const commissionStatusOptions = [
  { label: t('modules.affiliates.commission_status.pending'), value: 'pending', color: 'rgba(0,207,232, 0.8)' },
  { label: t('modules.affiliates.commission_status.approved'), value: 'approved', color: 'rgba(40,199,111, 0.8)' },
  { label: t('modules.affiliates.commission_status.refused'), value: 'refused', color: 'rgba(234,84,85, 0.8)' },
]

export const booleanStatusOptions = [
  { label: t('Active'), value: 1 },
  { label: t('Inactive'), value: 0 },
]

export const resolveOrderStatusVariant = status => {
  if (status === 'PEND') return 'warning'
  if (status === 'PAID') return 'warning'
  if (status === 'PRCS') return 'warning'
  if (status === 'RDYS') return 'info'
  if (status === 'SHPD') return 'warning'
  if (status === 'SDLV') return 'warning'
  if (status === 'RDYP') return 'info'
  if (status === 'DLVR') return 'success'
  if (status === 'RFSD') return 'danger'
  if (status === 'CNCL') return 'danger'
  if (status === 'RTRN') return 'secondary'
  return 'primary'
}

export const resolveShipmentStatusVariant = status => {
  if (status === 'created') return 'info'
  if (status === 'collected') return 'info'
  if (status === 'departed') return 'info'
  if (status === 'in_transit') return 'info'
  if (status === 'customs') return 'info'
  if (status === 'arrived_at_destination') return 'info'
  if (status === 'out_for_delivery') return 'info'
  if (status === 'action_required') return 'warning'
  if (status === 'delivered') return 'success'
  if (status === 'refused') return 'danger'
  if (status === 'cancelled') return 'danger'
  return 'secondary'
}

export const resolveSubscriptionStatusVariant = status => {
  if (status === 'PEND') return 'warning'
  if (status === 'ACTV') return 'primary'
  if (status === 'CNCL') return 'danger'
  if (status === 'EXPD') return 'danger'
  if (status === 'UPGD') return 'info'
  if (status === 'SUSP') return 'info'
  if (status === 'UPCM') return 'info'
  return 'primary'
}

export const colorOptions = (prefix = 'temp-') => [
  {
    id: `${prefix}ffffff`,
    name_en: 'White',
    name_ar: 'أبيض',
    internal_value: '#ffffff',
  },
  {
    id: `${prefix}000000`,
    name_en: 'Black',
    name_ar: 'أسود',
    internal_value: '#000000',
  },
  {
    id: `${prefix}ff0000`,
    name_en: 'Red',
    name_ar: 'أحمر',
    internal_value: '#ff0000',
  },
  {
    id: `${prefix}269326`,
    name_en: 'Green',
    name_ar: 'أخضر',
    internal_value: '#269326',
  },
  {
    id: `${prefix}0000ff`,
    name_en: 'Blue',
    name_ar: 'أزرق',
    internal_value: '#0000ff',
  },
  {
    id: `${prefix}ffff00`,
    name_en: 'Yellow',
    name_ar: 'أصفر',
    internal_value: '#ffff00',
  },
  {
    id: `${prefix}ffa500`,
    name_en: 'Orange',
    name_ar: 'برتقالي',
    internal_value: '#ffa500',
  },
  {
    id: `${prefix}a52a2a`,
    name_en: 'Brown',
    name_ar: 'بني',
    internal_value: '#964b00',
  },
  {
    id: `${prefix}c0c0c0`,
    name_en: 'Silver',
    name_ar: 'فضي',
    internal_value: '#c0c0c0',
  },
  {
    id: `${prefix}ffd700`,
    name_en: 'Gold',
    name_ar: 'ذهبي',
    internal_value: '#ffd700',
  },
  {
    id: `${prefix}00ff00`,
    name_en: 'Lime',
    name_ar: 'ليموني',
    internal_value: '#00ff00',
  },
  {
    id: `${prefix}00ffff`,
    name_en: 'Cyan',
    name_ar: 'سماوي',
    internal_value: '#00ffff',
  },
  {
    id: `${prefix}808080`,
    name_en: 'Gray',
    name_ar: 'رمادي',
    internal_value: '#808080',
  },
  {
    id: `${prefix}d3d3d3`,
    name_en: 'Light Gray',
    name_ar: 'رمادي فاتح',
    internal_value: '#d3d3d3',
  },
  {
    id: `${prefix}a9a9a9`,
    name_en: 'Dark Gray',
    name_ar: 'رمادي داكن',
    internal_value: '#a9a9a9',
  },
  {
    id: `${prefix}800000`,
    name_en: 'Maroon',
    name_ar: 'أحمر داكن (نبيتي)',
    internal_value: '#800000',
  },
  {
    id: `${prefix}000080`,
    name_en: 'Navy',
    name_ar: 'أزرق داكن',
    internal_value: '#000080',
  },
  {
    id: `${prefix}7fffd4`,
    name_en: 'Aqua',
    name_ar: 'أزرق مائي',
    internal_value: '#7fffd4',
  },
  {
    id: `${prefix}40e0d0`,
    name_en: 'Turquoise',
    name_ar: 'أزرق معدني',
    internal_value: '#40e0d0',
  },
  {
    id: `${prefix}008080`,
    name_en: 'Teal',
    name_ar: 'أزرق مخضر',
    internal_value: '#008080',
  },
  {
    id: `${prefix}808000`,
    name_en: 'Olive',
    name_ar: 'أخضر زيتوني',
    internal_value: '#808000',
  },
  {
    id: `${prefix}228b22`,
    name_en: 'Forest Green',
    name_ar: 'أخضر غابات',
    internal_value: '#228b22',
  },
  {
    id: `${prefix}32cd32`,
    name_en: 'Lime Green',
    name_ar: 'أخضر ليموني',
    internal_value: '#32cd32',
  },
  {
    id: `${prefix}006400`,
    name_en: 'Dark Green',
    name_ar: 'أخضر داكن (زيتي)',
    internal_value: '#053405',
  },
  {
    id: `${prefix}00ff7f`,
    name_en: 'Spring Green',
    name_ar: 'أخضر ربيعي',
    internal_value: '#00ff7f',
  },
  {
    id: `${prefix}dc143c`,
    name_en: 'Crimson',
    name_ar: 'قرمزي',
    internal_value: '#dc143c',
  },
  {
    id: `${prefix}8b0000`,
    name_en: 'Dark Red',
    name_ar: 'أحمر داكن',
    internal_value: '#8b0000',
  },
  {
    id: `${prefix}add8e6`,
    name_en: 'Light Blue',
    name_ar: 'أزرق فاتح',
    internal_value: '#add8e6',
  },
  {
    id: `${prefix}4169e1`,
    name_en: 'Royal Blue',
    name_ar: 'أزرق ملكي',
    internal_value: '#4169e1',
  },
  {
    id: `${prefix}89cff0`,
    name_en: 'Baby Blue',
    name_ar: 'أزرق فاتح (بيبي بلو)',
    internal_value: '#89cff0',
  },
  {
    id: `${prefix}87ceeb`,
    name_en: 'Sky Blue',
    name_ar: 'أزرق سماوي',
    internal_value: '#87ceeb',
  },
  {
    id: `${prefix}00008b`,
    name_en: 'Dark Blue',
    name_ar: 'أزرق داكن',
    internal_value: '#00008b',
  },
  {
    id: `${prefix}98fb98`,
    name_en: 'Pale Green',
    name_ar: 'أخضر فاتح',
    internal_value: '#98fb98',
  },
  {
    id: `${prefix}f0e68c`,
    name_en: 'Khaki',
    name_ar: 'كاكي',
    internal_value: '#f0e68c',
  },
  {
    id: `${prefix}ff00ff`,
    name_en: 'Fuchsia',
    name_ar: 'فوشيا',
    internal_value: '#ff00ff',
  },

  {
    id: `${prefix}800080`,
    name_en: 'Purple',
    name_ar: 'أرجواني (بنفسي)',
    internal_value: '#800080',
  },
  {
    id: `${prefix}ffc0cb`,
    name_en: 'Pink',
    name_ar: 'زهري',
    internal_value: '#ffc0cb',
  },
  {
    id: `${prefix}ff69b4`,
    name_en: 'Hot Pink',
    name_ar: 'زهري فاتح',
    internal_value: '#ff69b4',
  },
  {
    id: `${prefix}ffb6c1`,
    name_en: 'Baby Pink',
    name_ar: 'زهري فاتح 2',
    internal_value: '#ffb6c1',
  },
  {
    id: `${prefix}ff1493`,
    name_en: 'Deep Pink',
    name_ar: 'زهري غامق',
    internal_value: '#ff1493',
  },
  {
    id: `${prefix}fa8072`,
    name_en: 'Salmon',
    name_ar: 'سالمون',
    internal_value: '#fa8072',
  },
  {
    id: `${prefix}e9967a`,
    name_en: 'Dark Salmon',
    name_ar: 'سالمون داكن',
    internal_value: '#e9967a',
  },
  {
    id: `${prefix}ff6347`,
    name_en: 'Tomato',
    name_ar: 'طماطم',
    internal_value: '#ff6347',
  },
  {
    id: `${prefix}ff4500`,
    name_en: 'Orange Red',
    name_ar: 'أحمر برتقالي',
    internal_value: '#ff4500',
  },
  {
    id: `${prefix}ff8c00`,
    name_en: 'Dark Orange',
    name_ar: 'برتقالي داكن',
    internal_value: '#ff8c00',
  },
  {
    id: `${prefix}ff7f50`,
    name_en: 'Flory',
    name_ar: 'برتقالي فاتح',
    internal_value: '#ff7f50',
  },
  {
    id: `${prefix}e1aed1`,
    name_en: 'Rose',
    name_ar: 'وردي',
    internal_value: '#e1aed1',
  },
  {
    id: `${prefix}fcd9b2`,
    name_en: 'Beige',
    name_ar: 'بيج',
    internal_value: '#fcd9b2',
  },
  {
    id: `${prefix}8c7a4b`,
    name_en: 'Dark Beige',
    name_ar: 'بيج داكن',
    internal_value: '#8c7a4b',
  },
  {
    id: `${prefix}f5f5dc`,
    name_en: 'Light Beige',
    name_ar: 'بيج فاتح',
    internal_value: '#f5f5dc',
  },
  {
    id: `${prefix}fffff0`,
    name_en: 'Ivory',
    name_ar: 'عاجي',
    internal_value: '#fffff0',
  },
  {
    id: `${prefix}e6e6fa`,
    name_en: 'Lavandar',
    name_ar: 'لافندر',
    internal_value: '#e6e6fa',
  },
  {
    id: `${prefix}8ee53f`,
    name_en: 'Kiwi',
    name_ar: 'كيوي',
    internal_value: '#8ee53f',
  },
  {
    id: `${prefix}6b8e23`,
    name_en: 'Dark kiwi',
    name_ar: 'كيوي داكن',
    internal_value: '#6b8e23',
  },
  {
    id: `${prefix}654321`,
    name_en: 'Dark Brown',
    name_ar: 'بني داكن',
    internal_value: '#654321',
  },
  {
    id: `${prefix}c4a484`,
    name_en: 'Grand Latte',
    name_ar: 'لاتيه فاتح',
    internal_value: '#c4a484',
  },
]

export const sizeOptions = (prefix = 'temp-') => [
  {
    id: `${prefix}sm`,
    name_en: 'Small - SM',
    name_ar: 'صغير - SM',
    internal_value: 'sm',
  },
  {
    id: `${prefix}m`,
    name_en: 'Medium - M',
    name_ar: 'متوسط - M',
    internal_value: 'm',
  },
  {
    id: `${prefix}l`,
    name_en: 'Large - L',
    name_ar: 'كبير - L',
    internal_value: 'l',
  },
  {
    id: `${prefix}xl`,
    name_en: 'XL',
    name_ar: 'XL',
    internal_value: 'xl',
  },
  {
    id: `${prefix}xxl`,
    name_en: 'XXL',
    name_ar: 'XXL',
    internal_value: 'xxl',
  },
  {
    id: `${prefix}xxxl`,
    name_en: 'XXXL',
    name_ar: 'XXXL',
    internal_value: 'xxxl',
  },
]

export default {
  orderStatusOptions,
  commissionStatusOptions,
  booleanStatusOptions,
  resolveOrderStatusVariant,
}
