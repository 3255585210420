<template>
  <b-card :title="$t('cards.product_options')">
    <!-- Options -->
    <b-card-body class="invoice-padding form-item-section">
      <div
        ref="optionsForm"
        class="repeater-form"
      >
        <b-row
          ref="row"
          class="pb-2"
        >
          <!-- Item Form -->
          <!-- ? This will be in loop => So consider below markup for single item -->
          <b-col cols="12">

            <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
            <div class="d-none d-lg-flex">
              <b-row class="flex-grow-1 px-1">
                <!-- Single Item Form Headers -->
                <b-col
                  cols="12"
                  lg="5"
                >
                  {{ $t('modules.products.inputs.option_name') }}
                </b-col>
                <b-col
                  cols="12"
                  lg="7"
                >
                  {{ $t('modules.products.inputs.option_values') }}
                </b-col>
              </b-row>
              <div class="form-item-action-col" />
            </div>

            <!-- Form Input Fields OR content inside bordered area  -->
            <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
            <div
              v-for="(item, index) in localOptions"
              :key="index"
              class="d-flex border rounded"
            >
              <b-row class="flex-grow-1 p-2">
                <!-- Single Item Form Headers -->
                <b-col
                  cols="12"
                  lg="5"
                >
                  <label class="d-inline d-lg-none">{{ $t('modules.products.inputs.option_name') }}</label>
                  <i-input
                    v-model="item.name"
                    :errors="validationErrors.options"
                    type="select"
                    title="modules.products.inputs.option_name"
                    placeholder="modules.products.inputs.option_name:placeholder"
                    :no-label="true"
                    :clearable="false"
                    :options="availableNameOptions(item)"
                    :label="`name_${locale}`"
                    class="mb-2 item-selector-title"
                    push-tags
                    taggable
                    required
                    :input-bindings="{
                      noEmptySlot: true,
                      infoOption: $t(`modules.products.actions.write_to_add_option`),
                      createOption: (newOption) => ({
                        id: parseInt(Math.random()*10e9),
                        name_en: newOption,
                        name_ar: newOption,
                      })
                    }"
                    @input="item.values = []"
                  />
                </b-col>
                <b-col
                  cols="12"
                  lg="7"
                >
                  <label class="d-inline d-lg-none">{{ $t('modules.products.inputs.option_values') }}</label>
                  <i-input
                    v-model="item.values"
                    :errors="validationErrors.options"
                    :dirs="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    type="select"
                    title="modules.products.inputs.option_values"
                    placeholder="modules.products.inputs.option_values:placeholder"
                    :no-label="true"
                    :clearable="false"
                    :options="optionValueOptions(item)"
                    :label="`name_${locale}`"
                    class="mb-2 item-selector-title"
                    push-tags
                    multiple
                    taggable
                    :disabled="!item.name"
                    required
                    :input-bindings="{
                      noEmptySlot: true,
                      colorOptions: (item.type === 'Color') || (item.name && item.name.type === 'Color'),
                      infoOption: $t(`modules.products.actions.write_to_add_value`),
                      createOption: (newOption) => ({
                        id: parseInt(Math.random()*10e9),
                        name_en: newOption,
                        name_ar: newOption,
                      })
                    }"
                    @input="updateProductsList()"
                  />
                </b-col>
              </b-row>
              <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                <feather-icon
                  v-if="localOptions.length > 1"
                  size="16"
                  icon="XIcon"
                  class="cursor-pointer"
                  @click="removeOption(index)"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-button
        size="sm"
        variant="primary"
        @click="addNewOption"
      >
        {{ $t('modules.products.actions.add_option') }}
      </b-button>
    </b-card-body>
    <!-- Spacer -->
    <hr class="invoice-spacing">
    <!-- Products -->
    <b-card-body class="invoice-padding form-item-section">
      <div ref="productsForm">
        <b-row
          ref="row"
          class="pb-2"
        >
          <!-- Item Form -->
          <!-- ? This will be in loop => So consider below markup for single item -->
          <b-col cols="12">

            <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
            <div class="d-none d-lg-flex">
              <b-row class="flex-grow-1 px-1">
                <!-- Single Item Form Headers -->
                <b-col
                  cols="12"
                  lg="3"
                >
                  {{ $t('modules.products.s_c') }}
                </b-col>
                <b-col
                  cols="12"
                  lg="3"
                >
                  {{ $t('inputs.regular_price') }}
                </b-col>
                <b-col
                  cols="12"
                  lg="3"
                >
                  {{ $t('inputs.sale_price') }}
                </b-col>
                <b-col
                  cols="12"
                  lg="3"
                >
                  {{ $t('inputs.qty') }}
                </b-col>
              </b-row>
              <div class="form-item-action-col" />
            </div>

            <!-- Form Input Fields OR content inside bordered area  -->
            <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
            <div
              v-for="(item, index) in localProducts"
              :key="index"
              class="d-flex border rounded"
            >
              <b-row class="flex-grow-1 p-2">
                <!-- Single Item Form Headers -->
                <b-col
                  cols="12"
                  lg="3"
                >
                  <label class="d-inline d-lg-none">{{ $t('modules.products.s_c') }}:</label>
                  <small v-if="isProductionDebug()">#{{ item.id }}</small>
                  <p>{{ item.name[locale] }}</p>
                  <small v-if="isProductionDebug()">{{ item.options_values }}</small>
                </b-col>
                <b-col
                  cols="12"
                  lg="3"
                >
                  <label class="d-inline d-lg-none">{{ $t('inputs.regular_price') }}:</label>
                  <i-input
                    v-model="item.price"
                    :errors="validationErrors.price"
                    type="price"
                    title="inputs.regular_price"
                    :no-label="true"
                    required
                    min="0.01"
                    :currency="defaultCurrency"
                    @formatted-value-changed="(value) => {item.price_text=value}"
                  /></b-col>
                <b-col
                  cols="12"
                  lg="3"
                >
                  <label class="d-inline d-lg-none">{{ $t('inputs.sale_price') }}:</label>
                  <i-input
                    v-model="item.sale_price"
                    :errors="validationErrors.sale_price"
                    type="price"
                    title="inputs.sale_price"
                    :no-label="true"
                    min="0.01"
                    :validation-rules="['between:0.01,' + (item.price - 0.01)]"
                    :currency="defaultCurrency"
                    description="inputs.sale_price:description"
                    @formatted-value-changed="(value) => {item.sale_price_text=value}"
                  />
                </b-col>
                <b-col
                  cols="12"
                  lg="3"
                >
                  <label class="d-inline d-lg-none">{{ $t('inputs.qty') }}:</label>
                  <i-input
                    v-if="!item.has_unlimited_qty"
                    v-model="item.qty"
                    type="number"
                    title="inputs.qty"
                    :no-label="true"
                    min="0"
                    :disabled="item.has_unlimited_qty"
                    :required="!item.has_unlimited_qty"
                  />

                  <i-input
                    v-else
                    v-model="item.has_unlimited_qty"
                    :errors="validationErrors.has_unlimited_qty"
                    type="switch"
                    title="inputs.has_unlimited_qty"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </i-input>
                </b-col>
              </b-row>
              <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                <feather-icon
                  size="16"
                  icon="XIcon"
                  class="cursor-pointer"
                  :class="{invisible: localProducts.length<2}"
                  @click="removeProduct(index)"
                />
                <feather-icon
                  :id="`form-item-settings-icon-${index}`"
                  size="16"
                  icon="SettingsIcon"
                  class="cursor-pointer"
                />

                <!-- Setting Item Form -->
                <b-popover
                  :ref="`form-item-settings-popover-${index}`"
                  :target="`form-item-settings-icon-${index}`"
                  triggers="click"
                  placement="lefttop"
                >
                  <b-form @submit.prevent>
                    <b-row>

                      <!-- Field: Cost Price -->
                      <b-col cols="12">
                        <i-input
                          :id="`setting-item-${index}-cost`"
                          v-model="item.cost_price"
                          :errors="validationErrors.cost_price"
                          title="inputs.cost_price"
                          type="price"
                          min="0"
                          :validation-rules="['max_value:' + (item.price - 0.01)]"
                          :currency="defaultCurrency"
                          description="inputs.cost_price:description"
                          description-icon="EyeOffIcon"
                          muted-text="Used to show statistics for your performance"
                        />
                      </b-col>

                      <!-- Field: Unlimited QTY -->
                      <b-col cols="12">
                        <i-input
                          v-model="item.has_unlimited_qty"
                          :errors="validationErrors.has_unlimited_qty"
                          type="switch"
                          title="inputs.has_unlimited_qty"
                          description="inputs.has_unlimited_qty:description"
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </i-input>
                      </b-col>

                      <b-col md="12">
                        <i-input
                          v-model="item.weight_amount"
                          :errors="validationErrors.weight_amount"
                          type="number"
                          title="inputs.weight_amount"
                          label="inputs.weight"
                          min="0"
                          step="0.01"
                          :validation-rules="['min_value:0']"
                          :input-container-bindings="{
                            class: 'w-50'
                          }"
                        >
                          <template #append>
                            <v-select
                              v-model="item.weight_unit"
                              :errors="validationErrors.weight_unit"
                              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                              :options="['G','KG','Pound']"
                              input-id="weight_unit"
                              placeholder="inputs.weight_unit:placeholder"
                              :clearable="false"
                              :class="{'is-invalid' : validationErrors.weight_unit && validationErrors.weight_unit.length>0}"
                            />
                          </template>
                        </i-input>
                      </b-col>

                      <b-col
                        cols="12"
                        class="d-flex justify-content-between mt-1"
                      >
                        <b-button
                          variant="outline-secondary"
                          @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                        >
                          {{ $t('Close') }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-popover>
              </div>
            </div>
            <div v-if="!localProducts.length">
              <b-alert
                variant="info"
                show
                class="mt-1"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span
                    v-t="`modules.products._messages.no_options_so_no_products`"
                    class="ml-25"
                  />
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card-body>

  </b-card>
</template>

<script>

import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCol, BForm,
  BPopover,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from 'vue'
import { getLocale, getLocaleCurrency, isProductionDebug } from '@core/utils/utils'
import { colorOptions, sizeOptions } from '@core/utils/constants'

export default {
  components: {
    BAlert,
    BPopover,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      default: Object,
    },
    options: {
      type: Array,
      default: Array,
    },
    products: {
      type: Array,
      default: Array,
    },
  },
  methods: { getLocale },
  setup(props, { emit }) {
    const locale = getLocale()
    const blankOptionItem = {
      id: null,
      name: null,
      values: [],
    }
    const blankProductItem = {
      name: {
        en: null,
        ar: null,
      },
      price: props.value.price,
      price_text: props.value.price_text,
      sale_price: props.value.sale_price,
      sale_price_text: props.value.sale_price_text,
      cost_price: props.value.cost_price,
      weight_amount: props.value.weight_amount,
      weight_unit: props.value.weight_unit,
      has_unlimited_qty: props.value.has_unlimited_qty,
      qty: props.value.qty,
      options_values: {},
    }
    const localOptions = ref(props.options)
    const localProducts = ref(props.products)
    const optionsForm = ref()
    const productsForm = ref()

    const optionNameOptions = [
      {
        id: 'color',
        name_en: 'Color',
        name_ar: 'اللون',
        type: 'Color',
      },
      {
        id: 'size',
        name_en: 'Size',
        name_ar: 'المقاس',
        type: 'Text',
      },
    ]
    const availableNameOptions = item => optionNameOptions.filter(nameOption => !localOptions.value.filter(option => (item.id !== option.id) && (option.name) && (option.name.id === nameOption.id)).length)

    const optionValueOptions = item => {
      if (!item || !item.name) {
        return []
      }

      let options = []
      const selected = item.original_values

      if ((item.name?.id === 'color') || (item.type === 'Color') || (item.name.name_en === 'Color')) {
        options = colorOptions('temp-color-')
      }

      if ((item.name?.id === 'size') || (item.type === 'Size') || (item.name.name_en === 'Size')) {
        options = sizeOptions('temp-size-')
      }

      if (!selected || !selected.length) {
        return options
      }

      // Use the IDs of the already existing options instead of creating a new ones
      return options.map(o => {
        const newOption = o
        const similarSelectedOption = selected.filter(selectedOption => selectedOption.internal_value === o?.internal_value)
        if (similarSelectedOption.length) {
          newOption.id = similarSelectedOption[0].id
        }
        return newOption
      })
    }

    const reflectLocalUpdatesToParent = () => {
      emit('update:options', localOptions.value)
      emit('update:products', localProducts.value)
    }

    const reflectParentUpdatesToLocal = newValue => {
      localOptions.value = newValue.options
      localProducts.value = newValue.products
    }

    const updateItemForm = (index, val) => {
      return
      const { cost, qty, description } = val
      localProducts[index].cost = cost
      localProducts[index].qty = qty
      localProducts[index].description = description
      // TODO: Fire update event
    }

    const computeCombinations = () => {
      const optionsHaveValue = localOptions.value.filter(option => !!option.values.length)
      let combinations = []
      optionsHaveValue.forEach(option => {
        if (!option.values.length) {
          return
        }
        const initialCombinations = combinations.length ? combinations : [{}]
        combinations = []
        option.values.forEach(optionValue => {
          initialCombinations.forEach(combination => {
            const newCombination = { ...combination }
            newCombination[option.id] = optionValue.id
            combinations.push(newCombination)
          })
        })
      })
      return combinations
    }

    const getOptionsCombinationTitle = combination => {
      const title = { en: '', ar: '' }
      Object.keys(combination).forEach(optionId => {
        const option = localOptions.value.filter(o => String(o.id) === String(optionId))?.[0]
        if (!option) {
          console.log('Option not found', optionId)
          return
        }
        const optionValueId = combination[optionId]
        const optionValue = option.values.filter(ov => String(ov.id) === String(optionValueId))?.[0]
        if (!optionValue) {
          console.log('Option value not found', optionValueId)
          return
        }
        title.en += (title.en ? ' / ' : '') + optionValue.name_en
        title.ar += (title.ar ? ' / ' : '') + optionValue.name_ar
      })
      return title
    }

    const updateProductsList = () => {
      const combinations = computeCombinations()
      // localOptions.value.splice(index, 1)
      // Remove products of longer existing options combination and filter out the used combinations
      const newProductsList = localProducts.value.filter(product => {
        for (let i = 0; i < combinations.length; i += 1) {
          if (JSON.stringify(combinations[i]) === JSON.stringify(product.options_values)) {
            combinations.splice(i, 1)
            return true
          }
        }
        return false
      })
      // Append products for the new combinations
      combinations.forEach(combination => {
        const product = localProducts.value.length ? { ...localProducts.value[0] } : { ...blankProductItem }
        product.id = null
        product.name = getOptionsCombinationTitle(combination)
        product.options_values = combination
        newProductsList.push(product)
      })
      localProducts.value = newProductsList
      reflectLocalUpdatesToParent()
    }

    const addNewOption = () => {
      const newOption = JSON.parse(JSON.stringify(blankOptionItem))
      newOption.id = parseInt(Math.random() * 10e9, 10)
      localOptions.value.push(newOption)

      reflectLocalUpdatesToParent()
    }
    const removeOption = index => {
      if (localOptions.value.length < 2) {
        return
      }
      const shallUpdateProductsList = localOptions.value[index]?.values?.length
      localOptions.value.splice(index, 1)
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
      // TODO: Fire update event
      if (shallUpdateProductsList) {
        updateProductsList()
      } else {
        reflectLocalUpdatesToParent()
      }
    }
    const removeProduct = index => {
      if (localProducts.value.length < 2) {
        return
      }
      localProducts.value.splice(index, 1)
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
      // TODO: Fire update event
    }
    const initTrHeight = () => {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    }

    if (!localOptions.value.length) {
      addNewOption()
    }

    if (!localProducts.value.length) {
      updateProductsList()
    } else {
      // Fill names of already existing products
      try {
        localProducts.value = localProducts.value.map(product => ({ ...product, ...(product.options_values.length ? { name: getOptionsCombinationTitle(product.options_values) } : {}) }))
      } catch (e) {
        console.error(e)
      }
    }

    // watch([itemData.value.price, itemData.value.sale_price], () => {
    //   console.log('WATCH')
    //   if (itemData.value.sale_price >= itemData.value.price) {
    //     if (itemData.value.price >= 0.02) itemData.value.sale_price = itemData.value.price - 0.01
    //     else itemData.value.sale_price = null
    //   }
    // })

    const defaultCurrency = getLocaleCurrency()
    const validationErrors = ref({})

    return {
      locale,
      localOptions,
      localProducts,
      optionNameOptions,
      availableNameOptions,
      optionValueOptions,
      addNewOption,
      removeOption,
      removeProduct,
      updateProductsList,
      reflectLocalUpdatesToParent,
      reflectParentUpdatesToLocal,
      initTrHeight,
      updateItemForm,
      blankOptionItem,
      defaultCurrency,
      validationErrors,
      colorOptions,
      isProductionDebug,
    }
  },
}
</script>

<style lang="scss" scoped>

/* Product Options */
.product-color-options {
  margin-top: 1.5rem;
  margin-bottom: 1.2rem;
}

.product-color-options {
  .color-option {
    border: 1px solid transparent;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    padding: 3px;

    .filloption {
      height: 18px;
      width: 18px;
      border-radius: 50%;
    }
  }
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}
</style>
