var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-list-group',{staticClass:"d-flex",class:{
    'flex-column': _vm.mode === 'vertical',
    'flex-row': _vm.mode === 'horizontal',
  }},_vm._l((_vm.options),function(option){return _c('b-list-group-item',{key:option.code,staticClass:"cursor-pointer position-relative rounded",class:{
      'align-items-start flex-column': _vm.mode === 'vertical',
      'p-2 flex-grow-1 flex-shrink-1': _vm.mode === 'horizontal',
    },attrs:{"active":_vm.selectedOption === option[_vm.keyAttribute],"variant":"dark"},on:{"click":function($event){return _vm.$emit('input', _vm.selectedOption = option[_vm.keyAttribute])}}},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('h5',{staticClass:"m-1 text-black",class:{
          'text-black': _vm.selectedOption !== option[_vm.keyAttribute],
          'text-white': _vm.selectedOption === option[_vm.keyAttribute]
        }},[(_vm.labelPosition === 'before')?[_vm._v(" "+_vm._s(option[_vm.labelAttribute])+" ")]:_vm._e(),_c(_vm.mode === 'horizontal' ? 'div' : 'span',{tag:"component",class:{
            'text-center': _vm.mode === 'horizontal',
          }},[(option.logo)?_c('img',{class:{
              'pl-3': _vm.mode === 'vertical',
            },attrs:{"src":option.logo,"alt":option[_vm.labelAttribute],"height":"35","width":"35"}}):(option.icon)?_c('feather-icon',{attrs:{"icon":option.icon,"size":"35"}}):_vm._e()],1),(_vm.labelPosition === 'after')?_c('span',{class:{
            'mt-50 d-block text-center': _vm.mode === 'horizontal',
          }},[_vm._v(" "+_vm._s(option[_vm.labelAttribute])+" ")]):_vm._e()],2),(_vm.selectedOption === option[_vm.keyAttribute])?_c('small',{staticClass:"text-secondary position-absolute top-left",staticStyle:{"left":"0.5rem","top":"0.5rem"}},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1):_vm._e()])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }