import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import courierModel from './courierModel'

const {
  fetchOne,
} = useModelStoreModule(courierModel, true)

export default {
  name: courierModel.name,
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOne,
    fetchPickups: (ctx, id) => fetchOne(ctx, `${id}/pickups`),
  },
}
