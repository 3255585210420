import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import waybillModel from './waybillModel'

const {
  fetchAll,
  fetchOne,
  create,
  update,
  updateAttribute,
  deleteOne,
} = useModelStoreModule(waybillModel, true)

export default {
  name: waybillModel.name,
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll: (ctx, queryParams) => fetchAll(ctx, { with_suggestions: true, with_cities: true, ...queryParams }),
    fetchOne,
    fetchOptions: (ctx, orderId) => fetchOne(ctx, `create/${orderId}`),
    create,
    update,
    updateAttribute,
    deleteOne,
  },
}
