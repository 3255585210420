<template>
  <div
    class="list-view draft-shipment-items-list"
  >
    <b-row>
      <b-col
        cols="12"
        class="checkout-items"
      >
        <div
          v-for="product in items"
          :key="product.id"
          class="ecommerce-card shipment-item-card background-light"
        >
          <!-- Product Image -->
          <div class="item-img">
            <b-img
              class="sm-img"
              height="75px"
              width="auto"
              :src="product.image ? storagePath(product.image) : ImagePlaceholder"
              :alt="`${product.name}-${product.id}`"
            />
          </div>

          <!-- Product Details: Card Body -->
          <b-card-body>
            <div class="item-name">
              <h6 class="mb-0">
                <b-link
                  class="text-body"
                  :to="product.product_group_id ? {name: 'products.edit', params: {id: product.product_group_id}} : null"
                  target="_blank"
                >
                  {{ product.name }}
                </b-link>
              </h6>
              <span class="item-company"><b-link class="company-name">{{ product.category }}</b-link></span>
            </div>
            <ul
              v-if="product.options_values && product.options_values.length"
              class="mb-1"
            >
              <li
                v-for="(optionValue, k) in product.options_values"
                :key="`option-value-${k}`"
                class="text-muted"
              >
                {{ optionValue[`option_${$i18n.locale}`] }}: {{ optionValue[`value_${$i18n.locale}`] }}
              </li>
            </ul>
            <div class="item-quantity">
              <span>{{ product.qty }}</span>
              <span class="mx-1">x</span>
              <span v-if="product.unit_weight">{{ product.unit_weight / 1000 }} KG</span>
              <span
                v-else
                class="text-warning"
              >? KG</span>
            </div>
          </b-card-body>
        </div>
      </b-col>
    </b-row>

    <b-alert
      v-if="!items.length"
      variant="warning"
      class="mt-1"
      show
    >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="AlertTriangleIcon"
        />
        <span
          v-t="`modules.waybills._create._messages.no_unshipped_items`"
          class="ml-25"
        />
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BCardBody,
  BLink,
  BImg,
  VBToggle,
  BAlert, BCol, BRow,
} from 'bootstrap-vue'
import { formatDate, formatDatetime } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { storagePath } from '@core/utils/utils'
import { resolveShipmentStatusVariant } from '@core/utils/constants'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  components: {
    BRow,
    BCol,
    BCardBody,
    BLink,
    BImg,

    BAlert,
  },
  props: {
    items: {
      type: Array,
      default: Array,
    },
  },
  methods: { resolveShipmentStatusVariant, formatDatetime, storagePath },
  setup(props, ctx) {
    const ImagePlaceholder = require('@/assets/images/pages/eCommerce/placeholder.webp')

    return {
      // UI
      ImagePlaceholder,

      // Filter
      formatDate,
    }
  },
}
</script>

<style lang="css">
[dir] .draft-shipment-items-list .checkout-items {
  display: flex;
  flex-wrap: wrap;
}

[dir] .draft-shipment-items-list .checkout-items .shipment-item-card {
  background-color: #f6f6f6;
  flex: 1 0 33.333333%;
  grid-template-columns: 1fr 2fr;
}

[dir] .draft-shipment-items-list .v-select ul {
  position: absolute;
  top: auto;
  left: auto;
  width: inherit;
}

[dir] .ecommerce-application .draft-shipment-items-list .ecommerce-card.empty-card:hover {
  transform: unset;
  box-shadow: unset;
}
.ecommerce-application .draft-shipment-items-list.list-view .ecommerce-card {
  overflow: unset !important;
}

[dir] .ecommerce-application .draft-shipment-items-list .checkout-items .ecommerce-card .item-img img.sm-img {
  height: 75px;
  width: auto;
}

[dir="rtl"] .ecommerce-application .draft-shipment-items-list.list-view .shipment-item-card .card-body {
  border-left: unset;
}
[dir] .ecommerce-application .draft-shipment-items-list .ecommerce-card:hover {
  transform: unset;
  box-shadow: unset;
}
</style>
