<template>
  <validation-observer ref="refForm">
    <b-form
      class="auth-register-form mt-2"
      @submit.prevent="validateStep"
    >
      <div class="position-relative">
        <b-card-title class="mb-1">
          {{ $t('auth.ready.cards.store_locale.title') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('auth.ready.cards.store_locale.subtitle') }}
        </b-card-text>

        <template>
          <app-selection-btns
            :options="languageOptions"
            v-model="stepData.languages"
            :label="$t('auth.ready.cards.store_locale.inputs.store_languages')"
            :description="$t('auth.ready.cards.store_locale.inputs.store_languages:description')"
            class="mb-2"
            />

          <!-- Store Name -->
          <i-input
            id="store_name"
            ref="store_name"
            v-model="stepData.name"
            type="text"
            title="modules.settings.inputs.store_name"
            placeholder="modules.settings.inputs.store_name:placeholder"
            :translatable="true"
            :translatable-languages="!stepData.languages || stepData.languages === 'both' ? null : [stepData.languages]"
            :errors="{'ar': validationErrorsArray('name.ar'), 'en': validationErrorsArray('name.en')}"
            maxlength="100"
            :validation-rules="['required', 'min:2']"
          />

          <!-- Store Industry -->
          <i-input
            id="store_industry"
            v-model="stepData.industry_code"
            :errors="validationErrorsArray('industry_code')"
            type="select"
            title="modules.settings.inputs.store_industry"
            :options="industryOptions"
            :reduce="option => option.value"
            required
          />

          <i-input
            id="store_country"
            v-model="stepData.country_code"
            :errors="validationErrorsArray('country_code')"
            type="select"
            title="modules.settings.inputs.store_country"
            placeholder="modules.settings.inputs.store_country:placeholder"
            :options="countryOptions"
            :reduce="option => option.value"
            :clearable="true"
            required
          />

          <i-input
            id="store_currency"
            v-model="stepData.currency_code"
            :errors="validationErrorsArray('currency_code')"
            type="select"
            title="modules.settings.inputs.store_currency"
            placeholder="modules.settings.inputs.store_currency:placeholder"
            :options="currencyOptions"
            :reduce="option => option.value"
            :clearable="true"
            required
          />
        </template>

        <!-- submit button -->
        <b-button
          variant="primary"
          block
          type="submit"
          class="mt-2"
        >
          {{ $t(isFinalStep ? 'Save' : 'Next') }}
        </b-button>
        <b-button
          v-if="!isFirstStep"
          variant="light"
          block
          type="button"
          @click="$emit('back')"
        >
          {{ $t('Back') }}
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {
  getLanguagesList,
  platformName, platformUrl, toast, trackEvent,
} from '@core/utils/utils'
import {
  BButton, BCardText, BCardTitle, BForm, BTab, BTabs,
} from 'bootstrap-vue'
import { ref } from 'vue'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import useJwt from "@/auth/jwt/useJwt";
import { ValidationObserver } from 'vee-validate'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import AppSelectionBtns from "@core/components/app-selection-btns/AppSelectionBtns.vue";

export default {
  components: {
    AppSelectionBtns,
    BForm,
    BCardText,
    BCardTitle,
    BTabs,
    BTab,
    BButton,
    // BSV
    // validations
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  emits: ['submit', 'back'],
  props: {
    isFirstStep: {
      type: Boolean,
      default: false,
    },
    isFinalStep: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
      default: () => ({}),
    },
    industryOptions: {
      type: Array,
      default: () => [],
    },
    countryOptions: {
      type: Array,
      default: () => [],
    },
    currencyOptions: {
      type: Array,
      default: () => [],
    },
    remoteErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    trackEvent('InitiateReadinessStep2')
    // Set value of refs
    this.refs.value = this.$refs
  },
  methods: {
    platformUrl,
    platformName,
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const refForm = ref(null)
    const refs = ref({})
    const stepData = ref({
      step: 2,
      name: {
        en: null,
        ar: null,
      },
      industry_code: props.currentData.industry_code || null,
      country_code: props.currentData.country_code || null,
      currency_code: props.currentData.currency_code || null,
      languages: props.currentData.languages || getLanguagesList()[0].slug || null,
    })

    const validateStep = () => {
      refForm.value.validateWithInfo().then(({ isValid, errors, fields }) => {
        if (isValid) {
          emit('submit', stepData.value)
        } else {
          toast('warning', t('message.fill_required_fields'))

          const failedFields = Object.keys(fields).filter(key => fields[key].failed)
          const { focusOnTabsOfErrors } = useModelUpdate(null, null, null, null)
          focusOnTabsOfErrors(failedFields, refs)
        }
      })
    }

    const validationError = (attr) => {
      if (attr === 'name') {
        return validationError('name.ar') || validationError('name.en')
      }
      return props.remoteErrors[`${attr}`]?.[0] || null
    }
    const validationErrorsArray = (attr) => {
      const error = validationError(attr)
      return error ? [error] : []
    }


    const languageOptions = [
        ... getLanguagesList().map(language => ({'label': t('auth.ready.cards.store_locale.inputs.store_languages:options.' + language.slug), 'value': language.slug})),
      {'label': t('auth.ready.cards.store_locale.inputs.store_languages:options.both'), 'value': 'both'}
    ]

    return {
      refForm,
      refs,
      stepData,
      languageOptions,
      validateStep,
      validationError,
      validationErrorsArray,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.vs__dropdown-menu {
  max-height: 200px !important;
}

.ready-submit-btn {
  bottom: 22px;
  z-index: 99;
}
</style>
