<template>
  <div
    id="knowledge-base-article"
  >
    <b-row>
      <!-- General App Info -->
      <b-col
        cols="12"
      >
        <b-card>

          <!-- Title -->
          <!--          <b-card-title class="mb-1">-->
          <!--            {{ app.name }}-->
          <!--          </b-card-title>-->
          <!--          <b-card-text class="mb-2">-->
          <!--            Developer: {{ kb_question.lastUpdated }}-->
          <!--          </b-card-text>-->
          <b-row>
            <b-col md="9">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="80"
                    :variant="'light'"
                  >
                    <b-img
                      :src="app.image"
                      width="80"
                      height="auto"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <b-card-title class="mb-1">
                    {{ app.name }}
                  </b-card-title>
                  <small>{{ app.short_description }}</small>
                  <p
                    v-if="app.is_free"
                    class="pt-50"
                  >
                    <b-badge variant="light-success">
                      {{ $t('Free') }}
                    </b-badge>
                  </p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              class="align-content-end text-right"
            >
              <b-button
                v-if="app.can_submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.install-app
                variant="relief-success"
                block
              >
                {{ $t(`modules.apps.actions.${app.submit_action}_app`) }}
              </b-button>
              <template v-else-if="app.is_installed">
                <div v-if="app.can_update">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.install-app
                    variant="success"
                    block
                    @mouseenter="showEdit = true"
                    @mouseleave="showEdit = false"
                  >
                    <feather-icon :icon="showEdit ? `EditIcon` : `CheckIcon`" />
                    {{ $t(showEdit ? 'modules.apps.actions.edit' : 'modules.apps._messages.installed') }}
                  </b-button>
                </div>
                <div v-else>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    :disabled="true"
                    block
                  >
                    <feather-icon icon="CheckIcon" />
                    {{ $t('modules.apps._messages.installed') }}
                  </b-button>
                </div>
              </template>
              <b-button
                v-if="app.can_remove"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="light"
                class="mt-50"
                block
                @click="onClickRemoveHandler"
              >
                <feather-icon icon="XIcon" />
                {{ $t('modules.apps.actions.remove') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- Developer Info -->
      <b-col
        lg="3"
        md="5"
        order="2"
        order-md="1"
      >
        <b-card v-if="app.media_files && app.media_files.length">
          <b-carousel
            id="carousel-interval"
            controls
            indicators
            :interval="3000"
          >
            <b-carousel-slide
              v-for="(file, file_k) in app.media_files"
              :key="`media-file-${file_k}`"
              :img-src="file"
            />
          </b-carousel>
        </b-card>
        <b-card>
          <h6 class="kb-title">
            <feather-icon
              icon="InfoIcon"
              size="20"
              class="mr-50"
            />
            {{ $t('modules.apps._list.developer_information') }}
          </h6>
          <b-list-group class="list-group-circle mt-1">
            <b-list-group-item
              class="text-body"
            >
              {{ $t('inputs.name') }}: {{ app.developer.name }}
            </b-list-group-item>
            <b-list-group-item
              class="text-body"
            >
              {{ $t('inputs.email') }}: <a :href="`mailto:${app.developer.email}`">{{ app.developer.email }}</a>
            </b-list-group-item>
            <b-list-group-item
              class="text-body"
            >
              {{ $t('inputs.website') }}:
              <a
                :href="app.developer.website"
                target="_blank"
              >
                {{ app.developer.website }}
              </a>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>

      <!-- App Details -->
      <b-col
        lg="9"
        md="7"
        order="1"
        order-md="2"
      >

        <template v-for="(block, i) in app.description">

          <tutorial-steps
            v-if="block.type==='steps'"
            :key="`description-block-${i}`"
            :title="block.title"
            :steps="block.steps || []"
            :time="block.time"
          />

          <b-card
            v-else
            :key="`description-block-${i}`"
          >
            <!-- Title -->
            <b-card-title class="mb-1">
              {{ block.title || $t('modules.apps._list.app_details') }}
            </b-card-title>
            <!-- Content -->
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="question-content"
              v-html="block.value"
            />
            <!-- eslint-enable -->
          </b-card>
        </template>

      </b-col>
    </b-row>

    <AppInstallModal
      :app="app"
      @update:app="v => app = v"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BMediaBody,
  BAvatar,
  BMedia,
  BMediaAside,
  BImg, BButton, BBadge, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import store from '@/store'
import Vue, { onUnmounted, ref } from 'vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { catchException, getLocale, toast } from '@core/utils/utils'
import appStoreModule from '@/views/models/apps/appStoreModule'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import TutorialSteps from '@/views/models/apps/TutorialSteps.vue'
import AppInstallModal from '@/views/models/apps/install/AppInstallModal.vue'

export default {
  components: {
    AppInstallModal,
    BCarouselSlide,
    BCarousel,
    TutorialSteps,
    BBadge,
    BImg,
    BMediaAside,
    BMedia,
    BButton,
    BAvatar,
    BMediaBody,
    BRow,
    BCol,
    BCard,
    BListGroup,
    BCardTitle,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      //
    }
  },
  computed: {
    // submit_actionLabel() {
    //   if (this.app.is_installed || this.app.can_use) {
    //     return 'use'
    //   }
    //   return 'install'
    // },
  },
  created() {
    //
  },
  methods: {
    getLocale,
  },
  setup() {
    const STORE_MODULE_NAME = 'apps'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, appStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const app = ref({
      id: null,
      name: '',
      developer: {},
      description: [],
      submit_action: 'install',
      can_submit: false,
      is_installed: false,
      can_update: false,
      can_remove: false,
      settings: {},
    })
    const showEdit = ref(false)

    const isLoading = ref(false)
    const isRemoveLoading = ref(false)
    // Use toast
    const { t } = useI18nUtils()

    const removeApp = () => {
      isRemoveLoading.value = true
      store
        .dispatch(`${STORE_MODULE_NAME}/deleteOne`, app.value.id)
        .then(response => {
          toast(
            'success',
            t('Deleted!'),
            t('Item(s) has been deleted.'),
            'AlertTriangleIcon',
          )

          // eslint-disable-next-line no-use-before-define
          fetchApp()
        })
        .catch(e => {
          if (e?.response?.status === 422) {
            toast(
              'warning',
              e.response.data.message,
              null,
              'AlertTriangleIcon',
            )
            return
          }
          catchException(e)
          toast(
            'danger',
            t('message.error_fetching_list'),
            null,
            'AlertTriangleIcon',
          )
        })
        .finally(() => {
          isRemoveLoading.value = false
        })
    }

    const fetchApp = () => {
      store
        .dispatch(`${STORE_MODULE_NAME}/fetchOne`, router.currentRoute.params.app)
        .then(response => {
          // const oldDescription = app.value.description
          app.value = response
          // app.value.description = oldDescription

          try {
            document.getElementById('appBreadcrumb')
              .getElementsByClassName('router-link-active')[2]
              .textContent = response.category
          } catch (e) {
            // Do nothing
          }

          document.getElementById('appBreadcrumb')
            .getElementsByClassName('active')[0]
            .textContent = response.name
        })
        .catch(e => {
          if (e?.response?.status === 404) {
            router.push({ name: 'apps-index' })
            return
          }
          catchException(e)
          toast(
            'danger',
            t('message.error_fetching_list'),
            null,
            'AlertTriangleIcon',
          )
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    fetchApp()

    // Use toast
    const onClickRemoveHandler = () => {
      Vue.swal({
        title: t('Are you sure?'),
        text: t('modules.apps._messages.remove_confirmation_text'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: t('Cancel'),
        confirmButtonText: `${t('Yes, delete')} ${t('this item')}!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          removeApp()
        }
      })
    }
    return {
      app,
      showEdit,
      isLoading,
      onClickRemoveHandler,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
.modal-body label {
  font-size: unset;
}
</style>
