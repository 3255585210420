import axios from '@axios'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

const BASE_ENDPOINT = jwtDefaultConfig.DASHBOARD_ENDPOINT

export default function useModelStoreModule(
  model,
  tolerantToCreateResponse201 = false,
  routeResolver = null,
) {
  const resourceName = model.name
  const RESOURCE_ENDPOINT = `${BASE_ENDPOINT}/${resourceName}`

  const resolveRoute = routeResolver || ((action, itemId = null, childResource = null) => {
    if (action === 'list') {
      return `${RESOURCE_ENDPOINT}/list`
    }
    if (action === 'index') {
      return RESOURCE_ENDPOINT
    }
    if (action === 'indexChildren') {
      return `${RESOURCE_ENDPOINT}/${itemId}/${childResource}`
    }
    if (action === 'show') {
      return `${RESOURCE_ENDPOINT}/${itemId}`
    }
    if (action === 'create') {
      return RESOURCE_ENDPOINT
    }
    if (action === 'createChild') {
      return `${RESOURCE_ENDPOINT}/${itemId}/${childResource}`
    }
    if (action === 'update') {
      return `${RESOURCE_ENDPOINT}/${itemId}`
    }
    if (action === 'delete') {
      return `${RESOURCE_ENDPOINT}/${itemId}`
    }
    if (action === 'deleteMany') {
      return `${RESOURCE_ENDPOINT}/deleteMany`
    }
    if (action === 'sorting') {
      return `${RESOURCE_ENDPOINT}/sorting`
    }
    return null
  })

  const fetchList = (ctx, queryParams) => new Promise((resolve, reject) => {
    const queryParamsPlusUniqueParam = queryParams || {}
    queryParamsPlusUniqueParam.timestamp = new Date().getTime()

    axios
      .get(`${RESOURCE_ENDPOINT}/list`, { params: queryParamsPlusUniqueParam })
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
  const fetchAll = (ctx, queryParams) => new Promise((resolve, reject) => {
    axios
      .get(resolveRoute('index'), { params: queryParams })
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
  const fetchAllChildren = (ctx, id, childrenResourceName, queryParams) => new Promise((resolve, reject) => {
    axios
      .get(resolveRoute('indexChildren', id, childrenResourceName), { params: queryParams })
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
  const fetchOne = (ctx, id) => new Promise((resolve, reject) => {
    axios
      .get(resolveRoute('show', id))
      .then(response => {
        if (response && (response.status === 200)) {
          return resolve(model.fromArray(response.data.data))
        }
        return reject(new Error('Operation Failed!'))
      })
      .catch(error => {
        if (error?.response?.status === 422) {
          error.response.data.errors = model.resolveValidationErrors(error.response.data.errors)
        }
        return reject(error)
      })
  })
  const fetchOneWithMeta = (ctx, id) => new Promise((resolve, reject) => {
    axios
      .get(resolveRoute('show', id))
      .then(response => {
        if (response && (response.status === 200)) {
          return resolve({ ...response.data, data: model.fromArray(response.data.data) })
        }
        return reject(new Error('Operation Failed!'))
      })
      .catch(error => {
        if (error?.response?.status === 422) {
          error.response.data.errors = model.resolveValidationErrors(error.response.data.errors)
        }
        return reject(error)
      })
  })
  const create = (ctx, itemData) => new Promise((resolve, reject) => {
    axios
      .post(resolveRoute('create'), model.toArray(itemData))
      .then(response => {
        if (response && ((response.status === 201) || (tolerantToCreateResponse201 && (response.status === 200)))) {
          return resolve(model.fromArray(response.data.data))
        }
        return reject(new Error('Operation Failed!'))
      })
      .catch(error => {
        if (error?.response?.status === 422) {
          error.response.data.errors = model.resolveValidationErrors(error.response.data.errors)
        }
        return reject(error)
      })
  })
  const createChild = (ctx, { id, childResourceName, payload }) => new Promise((resolve, reject) => {
    axios
      .post(resolveRoute('createChild', id, childResourceName), payload)
      .then(response => {
        if (response && ((response.status === 201) || (tolerantToCreateResponse201 && (response.status === 200)))) {
          return resolve(model.fromArray(response.data.data))
        }
        return reject(new Error('Operation Failed!'))
      })
      .catch(error => {
        if (error?.response?.status === 422) {
          error.response.data.errors = model.resolveValidationErrors(error.response.data.errors)
        }
        return reject(error)
      })
  })
  const createWithMeta = (ctx, itemData) => new Promise((resolve, reject) => {
    axios
      .post(resolveRoute('create'), model.toArray(itemData))
      .then(response => {
        if (response && ((response.status === 201) || (tolerantToCreateResponse201 && (response.status === 200)))) {
          const transformedResponse = response.data
          transformedResponse.data = model.fromArray(transformedResponse.data)
          return resolve(transformedResponse)
        }
        return reject(new Error('Operation Failed!'))
      })
      .catch(error => {
        if (error?.response?.status === 422) {
          error.response.data.errors = model.resolveValidationErrors(error.response.data.errors)
        }
        return reject(error)
      })
  })
  const update = (ctx, itemData) => new Promise((resolve, reject) => {
    axios
      .put(resolveRoute('update', itemData.id), model.toArray(itemData))
      .then(response => {
        if (response && (response.status === 200)) {
          return resolve(model.fromArray(response.data.data))
        }
        return reject(new Error('Operation Failed!'))
      })
      .catch(error => reject(error))
  })
  const updateAttribute = (ctx, { itemId, attribute, value }) => new Promise((resolve, reject) => {
    const data = {}
    data[attribute] = value
    axios
      .put(resolveRoute('update', itemId), data)
      .then(response => {
        if (response && (response.status === 200)) {
          return resolve(model.fromArray(response.data.data))
        }
        return reject(new Error('Operation Failed!'))
      })
      .catch(error => reject(error))
  })
  const deleteMany = (ctx, ids) => new Promise((resolve, reject) => {
    axios
      .delete(resolveRoute('deleteMany'), { data: { items: ids } })
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
  const deleteOne = (ctx, id) => new Promise((resolve, reject) => {
    if (!id) {
      reject(Error('Invalid item selected'))
    }
    axios
      .delete(resolveRoute('delete', id))
      .then(response => resolve(response))
      .catch(error => reject(error))
  })

  const sorting = (ctx, data) => new Promise((resolve, reject) => {
    axios
      .put(resolveRoute('sorting'), data)
      .then(response => {
        if (response && (response.status === 200)) {
          return resolve(response.data.data)
        }
        return reject(new Error('Operation Failed!'))
      })
      .catch(error => reject(error))
  })

  return {
    RESOURCE_ENDPOINT,
    fetchAll,
    fetchAllChildren,
    fetchOne,
    fetchOneWithMeta,
    fetchList,
    create,
    createChild,
    createWithMeta,
    updateAttribute,
    update,
    deleteOne,
    deleteMany,
    sorting,
    resolveRoute,
  }
}
