import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import addressModel from '@/views/models/addresses/addressModel'

const {
  create,
  deleteOne,
} = useModelStoreModule(addressModel)

export default {
  name: 'addresses',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    create,
    deleteOne,
  },
}
