import Vue from 'vue'
import store from '@/store'
import { getStoreData, isUserLoggedIn } from '@/auth/utils'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

const fetchUpdates = () => {
  const uniqueParam = `timestamp=${new Date().getTime()}`
  Vue.prototype.$http.get(`${jwtDefaultConfig.DASHBOARD_ENDPOINT}/sync?${uniqueParam}`).then(response => {
    const { data } = response.data

    store.commit('appConfig/UPDATE_MENU_COUNTER_CONFIG', data.counters)
    store.commit('storeConfig/UPDATE_URL_READY', data.configs.isUrlReady)
    store.commit('storeConfig/UPDATE_THEME_READY', data.configs.isThemeReady)
  }).catch(() => {

  })
}

const loop = (isFirstRun = false) => {
  if (!isFirstRun && isUserLoggedIn() && getStoreData()?.is_ready) {
    fetchUpdates()
  }
  setTimeout(() => {
    loop()
  }, 60000)
}

loop(true)
