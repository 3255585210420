<template>
  <b-card
    class="ecommerce-card"
    :border-variant="item.isInstalled ? item.isActive ? 'success' : 'danger' : null"
    no-body
  >
    <!-- Product Image -->
    <div class="item-img">
      <b-link>
        <b-img
          :src="CompaniesLogos[item.image] || item.logo"
          :alt="`${item.name}`"
          class="p-50"
        />
      </b-link>
    </div>

    <!-- Product Details: Card Body -->
    <b-card-body>
      <div class="item-name">
        <h6 class="mb-0">
          <b-link class="text-body font-arabic">
            {{ item.name }}
          </b-link>
        </h6>
        <span
          v-if="item.courier.name"
          class="item-company"
        >{{ $t('By') }} <b-link
          class="company-name"
          :href="item.courier.url"
          target="_blank"
        >{{ item.courier.name }}</b-link></span>
        <span
          v-else-if="item.courierCode"
          class="item-company"
        ><b-link
          href="#"
          class="company-name"
        >{{ $t(`modules.shipping_methods.providers.${item.courierCode}`) }}</b-link></span>
      </div>
      <!--      <div class="item-quantity mt-1">-->
      <!--        <span class="quantity-title">{{ $t(`modules.shipping_methods.details.shipping_fees`) }}:</span>-->
      <!--        <span class="px-50">{{ $n(12, 'currency') }} - {{ $n(30, 'currency') }}</span>-->
      <!--      </div>-->
      <div class="mt-auto">
        <div
          v-if="item.courier.hasLimitedSupport"
        >
          <span class="text-secondary">{{ item.courier.supportedCountriesCount }} Supported countries</span>
          <span> - </span>
          <span
            v-if="item.courier.isCountrySupported"
            class="text-success"
          >{{ 'Your country' }} Supported</span>
          <span
            v-else
            class="text-warning"
          >{{ 'Your country' }} Not Supported</span>
        </div>
        <span
          v-if="item.is_courier_controls_rates"
          class="text-secondary"
        >
          <material-icon
            icon="Bolt"
            size="20"
          />
          <span v-t="`modules.shipping_methods._list.automatic_rates`" />
        </span>
        <span
          v-else-if="item.hasActiveZonesInfo"
          class="text-secondary"
        >
          <material-icon
            icon="request_quote"
            size="20"
          />
          <span v-t="`modules.shipping_methods._list.shipping_rates`" />: {{ item.activeZonesCount }}
        </span>
        <div

          v-if="item.hasActiveZonesInfo"
          class="pt-1"
        >
          <span
            v-if="item.isCodAllowed"
            class="delivery-date text-success"
          >
            <feather-icon
              icon="CheckIcon"
              size="20"
            />
            <span v-t="`modules.shipping_methods._list.cod_is_allowed`" />
          </span>
          <span
            v-else
            class="delivery-date text-secondary"
          >
            <feather-icon
              icon="XIcon"
              size="20"
            />
            <span v-t="`modules.shipping_methods._list.cod_is_not_allowed`" />
          </span>
        </div>
      </div>
    </b-card-body>

    <!-- Product Options/Actions -->
    <div class="item-options text-center">
      <div class="item-wrapper">
        <div
          v-if="item.isInstalled"
          class="item-cost"
        >
          <!--          <h4 class="item-price">-->
          <!--            ${{ item.price }}-->
          <!--          </h4>-->
          <p
            class="card-text shipping"
          >
            <b-form-checkbox
              :id="`${item.code}_is_active`"
              v-model="item.isActive"
              class="mb-1"
              switch
              :disabled="isLoading"
              @change="val => {updateStatus(item.code, val)}"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>

            </b-form-checkbox>
            <b-badge
              v-if="item.isActive"
              v-t="`Active`"
              pill
              variant="light-success"
            />
            <b-badge
              v-else
              v-t="`Inactive`"
              pill
              variant="light-danger"
            />
          </p>
        </div>
      </div>
      <b-button
        v-for="action in item.actions || []"
        :key="`action-${action.code}`"
        variant="outline-primary"
        class="mt-1"
        :disabled="isLoading"
        @click="$emit('click-action', action)"
      >
        <feather-icon
          :icon="action.icon"
          class="mr-50"
        />
        <span>{{ action.title }}</span>
      </b-button>
      <b-button
        v-if="item.hasEditableSettings"
        v-b-modal.modal-add-new
        variant="outline-primary"
        class="btn-cart move-cart"
        :disabled="isLoading"
        @click="$emit('click-edit', item.code)"
      >
        <feather-icon
          icon="SettingsIcon"
          class="mr-50"
        />
        <span
          v-t="`modules.shipping_methods.actions.edit`"
          class="text-nowrap"
        />
      </b-button>
      <b-button
        v-if="item.canUninstall"
        variant="light"
        class="mt-1 remove-wishlist"
        :disabled="isLoading"
        @click="deleteItem(item.code)"
      >
        <feather-icon
          icon="TrashIcon"
          class="mr-50"
        />
        <span v-t="`modules.shipping_methods.actions.delete`" />
      </b-button>
      <b-button
        v-if="item.canInstall"
        v-b-modal.modal-add-new
        variant="primary"
        class="btn-cart move-cart"
        @click="$emit('click-add', item.code)"
      >
        <feather-icon
          icon="PowerIcon"
          class="mr-50"
        />
        <span
          v-t="`modules.shipping_methods.actions.enable`"
          class="text-nowrap"
        />
      </b-button>
      <div
        class="item-wrapper"
      >
        <div
          class="item-cost"
        >
          <template
            v-if="item.courier.isComingSoon"
          >
            <p
              class="card-text"
            >
              <b-badge
                v-t="`Coming Soon`"
                pill
                size="5pt"
                variant="light-secondary"
              />
            </p>
            <b-button
              variant="outline-primary"
              class="btn-cart move-cart"
              href="https://ro0nri20lzv.typeform.com/to/Hkuo5M0s"
              target="_blank"
            >
              <feather-icon
                icon="CheckSquareIcon"
                class="mr-50"
              />
              <span
                v-t="`modules.shipping_methods.actions.request_this_integration`"
                class="text-nowrap"
              />
            </b-button>
          </template>
          <p
            v-if="item.showUnavailableMessage"
            class="card-text"
          >
            <b-badge
              v-t="`modules.shipping_methods._messages.not_available_for_store_country`"
              pill
              size="5pt"
              variant="light-secondary"
            />
          </p>
        </div>
      </div>

    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from 'vue'
import useModelDelete, {
  DEFAULT_ALWAYS_CALLBACK,
  DEFAULT_ON_APPROVE_CALLBACK, DEFAULT_ON_FAILURE_CALLBACK,
  DEFAULT_ON_SUCCESS_CALLBACK,
} from '@/views/models/common/useModelDelete'
import shippingMethodStoreModule from '@/views/models/shipping_methods/shippingMethodStoreModule'

const CompaniesLogos = {
  pickup: require('@/assets/images/icons/cash-counter-sm.png'),
  custom: require('@/assets/images/icons/delivery-truck-sm.png'),
  egypt_post: require('@/assets/images/logos/couriers/egypt_post.png'),
  aramex: require('@/assets/images/logos/couriers/aramex.png'),
  fedex: require('@/assets/images/logos/couriers/fedex.png'),
  smsa: require('@/assets/images/logos/couriers/smsa.png'),
  salasa: store.state.appConfig.layout.skin === 'dark' ? require('@/assets/images/logos/couriers/salasa-dark.webp') : require('@/assets/images/logos/couriers/salasa.webp'),
  fastlo: require('@/assets/images/logos/couriers/fastlo.png'),
}

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormCheckbox,
  },
  emits: ['click-add', 'click-edit', 'click-action', 'item-update'],
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      previousStatus: false,
    }
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'shipping_methods'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, shippingMethodStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const isLoading = ref(false)

    const {
      deleteItem,
    } = useModelDelete(
      STORE_MODULE_NAME,
      false,
      () => {
        DEFAULT_ON_APPROVE_CALLBACK()
        isLoading.value = true
      },
      () => {
        DEFAULT_ON_SUCCESS_CALLBACK()
        ctx.emit('item-update')
      },
      () => {
        DEFAULT_ON_FAILURE_CALLBACK()
      },
      () => {
        DEFAULT_ALWAYS_CALLBACK()
        isLoading.value = false
      },
    )

    const previousStatus = ref(false)
    const onStatusUpdateSuccess = () => {
      const { item } = props
      previousStatus.value = item.is_active
      isLoading.value = false
    }
    const onStatusUpdateFailure = () => {
      const { item } = props
      item.is_active = previousStatus.value
      isLoading.value = false
    }
    const updateStatus = (itemId, newStatus) => {
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/updateAttribute`, { itemId, attribute: 'is_active', value: newStatus })
        .then(response => {
          onStatusUpdateSuccess()
        })
        .catch(() => {
          onStatusUpdateFailure()
        })
    }
    return {
      CompaniesLogos,
      isLoading,
      previousStatus,
      deleteItem,
      updateStatus,
    }
  },
}
</script>

<style>

</style>
