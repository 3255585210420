import Vue from 'vue'

// axios
import axios from 'axios'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'
import {
  appendInProgressRequest, env, removeInProgressRequest, toast,
} from '@core/utils/utils'

import useSession from '@/auth/jwt/useJwt'
import { markServerAsDown, markServerAsUp } from '@/auth/utils'
import router from '@/router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: env('VUE_APP_BACKEND_API_HOST', 'http://api.localhost'),
  // crossDomain: true, // TODO: Check if required
  // timeout: 3000,
})

// axiosIns.defaults.crossDomain = true
axiosIns.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axiosIns.defaults.headers.common['Content-Type'] = 'application/json'

// Set default headers to disable cache
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store, must-revalidate'
axios.defaults.headers.get['Pragma'] = 'no-cache'
axios.defaults.headers.get['Expires'] = '0'

// this.axiosIns.defaults.headers.common['Content-Type'] = 'application/json'
// TODO: Enable
// this.axiosIns.defaults.credentials = true

// Add request/response interceptor
axiosIns.interceptors.response.use(
  (response, arg2) => {
    markServerAsUp()
    removeInProgressRequest(response.config.requestId)
    return response
  },
  error => {
    // const { config, response: { status } } = error
    const { config, response } = error
    // const originalRequest = config

    if (config) {
      removeInProgressRequest(config.requestId)
    }

    // if network error
    if (!error.code && error.message === 'Network Error') {
      if (!response || !response.request || response.request.responseURL !== jwtDefaultConfig.syncEndpoint) {
        toast(
          'danger',
          'Server unreachable',
          'We can\'t reach our server, this may happens due to maintenance or internet issue.',
          'AlertTriangleIcon',
          'top-right',
        )
        markServerAsDown()
        console.log('NETWORK ERROR', response)
      }
    } else {
      markServerAsUp()
    }

    // if status === 401
    if (response && response.status === 401) {
      if (response.request.responseURL !== jwtDefaultConfig.logoutEndpoint) {
        useSession.logout()
      }

      // Revoke token immediately before redirecting to login page
      useSession.revokeToken()

      // Redirect to login page
      useSession.redirectToLoginPage(router.currentRoute.path)
      // eslint-disable-next-line brace-style
    } else if (response && response.status === 402) { // CSRF Token Response
      // console.log('Debug #402', response, response.headers)
    } else if (response && response.status === 403) { // 403 - Unauthorized
      router.push({ name: 'misc-not-authorized' })
    } else if (response && response.status === 310) { // Store Not Ready Exception
      if (router.currentRoute.name !== 'auth-ready') {
        router.push({ name: 'auth-ready' })
      }
    } else if (response && response.status === 503) { // Platform is in maintenance mode
      markServerAsDown()
    }
    return Promise.reject(error)
  },
)

axiosIns.interceptors.request.use(config => {
  // eslint-disable-next-line no-param-reassign
  config.requestId = Date.now()
  if (!(config.url.endsWith(`${jwtDefaultConfig.DASHBOARD_ENDPOINT}/sync`))) {
    appendInProgressRequest(config.requestId)
  }
  return config
})

Vue.prototype.$http = axiosIns

export default axiosIns
