<template>
  <b-form-group
    :id="id"
    :label="noLabel ? null : translated(title || this.$attrs.label)"
    :label-for="id"
    :title="tooltip || null"
    :data-toggle="tooltip ? 'tooltip' : null"
    :description="description && type!=='image' ? translated(description) : null"
  >
    <template #description>
      <span
        v-if="htmlDescription"
        v-html="htmlDescription"
      />
      <span v-else-if="descriptionCallback">
        {{ descriptionCallback(languages[translationTabIndex]) }}
      </span>
      <span v-else>
        {{ description && type!=='image' ? translated(description) : null }}
      </span>
    </template>

    <component
      :is="!!$slots.append||!!$slots.prepend||allInputBindings.append||allInputBindings.prepend ? 'b-input-group' : 'div'"
      :append="allInputBindings.append ? allInputBindings.append : ''"
      :prepend="allInputBindings.prepend ? allInputBindings.prepend : ''"
    >
      <b-input-group-prepend
        v-if="!!$slots.prepend"
      >
        <slot
          v-slot="prepend"
          name="prepend"
        />
      </b-input-group-prepend>

      <!-- Translatable -->
      <template v-if="translatable">
        <b-tabs
          ref="translationTabs"
          v-model="translationTabIndex"
          align="right"
          pills
          class="translatable-card"
          :class="{'single-lang-translatable-card': languages.length<2}"
          nav-wrapper-class="nav-wrapper"
        >
          <template v-for="(language, langIndex) in languages">
            <b-tab
              :key="id + '_' + language.slug"
              :active="langIndex===0"
              :title="$t(language.name)"
            >
              <validation-provider
                #default="{ errors }"
                :rules="finalValidationRules.join('|')"
                :vid="`${id}_${language.slug}`"
                :name="`${$t(title)} ` + (language.name && languages.length>1 ? `(`+$t(language.name)+`)` : '')"
                v-bind="inputContainerBindings"
              >
                <component
                  :is="input"
                  :id="`${id}_${language.slug}`"
                  v-model="inputValue[language.slug]"
                  :errors="errors || externalValidationError(language.slug)"
                  v-bind="allInputBindings"
                  :class="{'is-invalid' : errors[0] || externalValidationError(language.slug)}"
                  :related-lang="language"
                >
                  <!--                v-on="$listeners"-->
                  <slot
                    v-for="(_, name) in childSlots"
                    :slot="name"
                    :name="name"
                  />
                </component>

                <small
                  v-if="errors"
                  class="text-danger"
                >{{ errors[0] || externalValidationError(language.slug) }}</small>
                <!-- errors[0] || externalValidationError(language.slug)[0] || null -->

              </validation-provider>
            </b-tab>
          </template>
        </b-tabs>

      </template>

      <!-- Not translatable -->
      <template v-else>

        <validation-provider
          #default="{ errors }"
          :rules="finalValidationRules.join('|')"
          :name="translated(title)"
          v-bind="inputContainerBindings"
        >
          <component
            :is="input"
            v-model="inputValue"
            v-bind="allInputBindings"
            :class="{'is-invalid' : (errors.length > 0) || ($attrs.errors && $attrs.errors.length>0)}"
            :aria-invalid="(errors.length > 0) || ($attrs.errors && $attrs.errors.length>0)"
            v-on="$listeners"
          >
            <slot
              v-for="(_, name) in childSlots"
              :slot="name"
              :name="name"
            />
          </component>

          <small
            v-if="errors"
            class="text-danger"
          >{{ errors[0] }}</small>

        </validation-provider>
      </template>

      <b-input-group-append
        v-if="!!$slots.append"
      >
        <slot
          v-slot="append"
          :name="`append`"
        />
      </b-input-group-append>
    </component>

    <!--      Validation rules: {{ finalValidationRules }}-->

    <!--    <small-->
    <!--        v-if="errors"-->
    <!--        class="text-danger"-->
    <!--    >{{ errors[0] }}</small>-->
  </b-form-group>
</template>

<script>
import uniqueId from 'vue-select/src/utility/uniqueId'
import TextInput from '@core/components/i-input/TextInput.vue'
import SelectInput from '@core/components/i-input/SelectInput.vue'
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import PriceInput from '@core/components/i-input/PriceInput.vue'
import TelInput from '@core/components/i-input/TelInput.vue'
import CheckboxInput from '@core/components/i-input/CheckboxInput.vue'
import RadioInput from '@core/components/i-input/RadioInput.vue'
import TextareaInput from '@core/components/i-input/TextareaInput.vue'
import MediaInput from '@core/components/i-input/MediaInput.vue'
import EditorInput from '@core/components/i-input/EditorInput.vue'
import { getLanguagesList } from '@core/utils/utils'
import ImageInput from '@core/components/i-input/ImageInput.vue'
import FileInput from '@core/components/i-input/FileInput.vue'
// eslint-disable-next-line no-unused-vars
import {
  required, email, min_value, max_value, integer, numeric, between, min,
} from '@validations' // Don't remove, they are required for vee-validate to work properly

const inputTypesMap = {
  text: TextInput,
  url: TextInput,
  email: TextInput,
  color: TextInput,
  password: TextInput,
  number: TextInput,
  price: PriceInput,
  tel: TelInput,
  select: SelectInput,
  radio: RadioInput,
  checkbox: CheckboxInput,
  switch: CheckboxInput,
  textarea: TextareaInput,
  editor: EditorInput,
  images: MediaInput,
  image: ImageInput,
  file: FileInput,
}

const predictValidationRules = instance => {
  const rules = []
  if (instance.required) rules.push('required')
  if (instance.type === 'number') {
    if (!instance.$attrs.step || instance.$attrs.step === 1) rules.push('integer')
    else rules.push('numeric')
  } else if (instance.type === 'price') {
    // rules.push('numeric')
  }
  if (instance.$attrs.min && instance.$attrs.max) rules.push(`between:${instance.$attrs.min},${instance.$attrs.max}`)
  else {
    if (instance.$attrs.min) rules.push(`min_value:${instance.$attrs.min}`)
    if (instance.$attrs.max) rules.push(`max_value:${instance.$attrs.max}`)
  }
  return rules
}

export default {
  name: 'IInput',
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    ValidationProvider,
    BTabs,
    BTab,
  },
  props: {
    id: {
      type: String,
      default: () => `input-${uniqueId()}`,
    },
    type: {
      required: true,
      type: String,
    },
    title: {
      type: String,
      default: null,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    options: {
      type: [Object, Array],
      default: Array,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Array, Object, File, Boolean],
      default: null,
    },
    validationRules: {
      type: Array,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    htmlDescription: {
      type: String,
      default: null,
    },
    descriptionCallback: {
      type: Function,
      default: null,
    },
    translatable: {
      type: Boolean,
      default: false,
    },
    translatableLanguages: {
      type: Array,
      default: null,
    },
    translate: {
      type: Boolean,
      default: true,
    },
    inputBindings: {
      type: Object,
      default: Object,
    },
    inputContainerBindings: {
      type: Object,
      default: Object,
    },
  },
  data() {
    let { value } = this
    if (this.translatable && (value === null)) {
      // Workaround to not break when the input value is null
      value = {
        en: null,
        ar: null,
      }
    }
    return {
      inputValue: value,
      translationTabIndex: 0,
    }
  },
  computed: {
    languages() {
      return this.translatableLanguages ? getLanguagesList(false).filter(l => this.translatableLanguages.indexOf(l.slug) !== -1) : getLanguagesList()
    },
    input() {
      return inputTypesMap[this.type]
    },
    allInputBindings() {
      const bindings = { ...this.$props, ...this.$attrs, ...this.inputBindings }
      delete bindings.validationRules

      if (this.type === 'price') {
        // bindings.type = 'number'
        // delete bindings.options
        // delete bindings.type
        bindings.curreny = bindings.currency || this.$store.state.appConfig.localization.currency
        bindings.locale = this.$store.state.appConfig.localization.currencyLocale
      } else if (this.type === 'switch') {
        bindings.isSwitch = true
        delete bindings.id
      } else if (this.type === 'editor') {
        delete bindings.options
        delete bindings.title
      } else if (this.type === 'images') {
        delete bindings.title
      }

      // Localizations
      if (bindings.title) {
        bindings.title = this.translated(bindings.title)
      }
      if (bindings.placeholder) {
        bindings.placeholder = this.translated(bindings.placeholder)
      }
      return bindings
    },
    childSlots() {
      const allSlots = this.$slots
      const targetSlots = {}
      Object.keys(allSlots).forEach(k => {
        if (k !== 'append' && k !== 'prepend') {
          targetSlots[k] = allSlots[k]
        }
      })
      // @REF: Check this article for scopedSlots: https://gist.github.com/loilo/73c55ed04917ecf5d682ec70a2a1b8e2
      return targetSlots
    },
    parentSlots() {
      const allSlots = this.$slots
      const targetSlots = {}
      Object.keys(allSlots).forEach(k => {
        if (k === 'append' || k === 'prepend') {
          targetSlots[k] = allSlots[k]
        }
      })
      return targetSlots
    },
    slotAppend() {
      return this.$slots.append
    },
    slotPrepend() {
      return this.$slots.prepend
    },
    finalValidationRules() {
      return this.validationRules || predictValidationRules(this)
    },
  },
  watch: {
    value(newValue) {
      if (!newValue && this.translatable) {
        this.inputValue = { en: null, ar: null }
        return
      }
      this.inputValue = newValue
    },
  },
  mounted(args) {
    // console.log('mounted', this, args)
  },
  created(args) {
    // console.log('created', this, args)
  },
  methods: {
    /**
     * @param {string} lang
     */
    selectTabOfLang(lang) {
      const ctx = this
      this.languages.forEach((langData, index) => {
        if (langData.slug === lang) {
          ctx.translationTabIndex = index
        }
      })
    },
    externalValidationError(lang) {
      return this.$attrs?.errors?.[lang]?.[0] || null
    },
    translated(str) {
      return this.translate && (typeof str === 'string') ? this.$t(str) : str
    },
  },
  // setup(props, { attrs, listeners }) {
  //   // console.log('Setup -> attrs -> listeners', listeners)
  //   // console.log('in setup', this.$listeners)
  // },
}
</script>

<style lang="scss">

</style>
