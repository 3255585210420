import { render, staticRenderFns } from "./Ready-Step1.vue?vue&type=template&id=b39b35de&"
import script from "./Ready-Step1.vue?vue&type=script&lang=js&"
export * from "./Ready-Step1.vue?vue&type=script&lang=js&"
import style0 from "./Ready-Step1.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports