<template>
  <b-modal
    id="modal-import-products"
    ref="refModel"
    :title="$t('modules.products.actions.import_csv')"
    :ok-title="$t('Submit')"
    :cancel-title="$t('Cancel')"
    cancel-variant="outline-secondary"
    :ok-disabled="isProductOperationRequestLoading || isProductOperationPending"
    :cancel-disabled="isProductOperationRequestLoading || isProductOperationPending"
    :no-close-on-backdrop="isProductOperationRequestLoading || isProductOperationPending"
    :no-close-on-esc="isProductOperationRequestLoading || isProductOperationPending"
    :hide-header-close="isProductOperationRequestLoading || isProductOperationPending"
    :busy="isProductOperationRequestLoading || isProductOperationPending"
    :hide-footer="isProductOperationPending"
    ok-only
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <template v-if="isProductOperationPending">
      <div class="d-flex align-items-center">
        <span class="mr-auto"><b-spinner /></span>
        <p class="pt-50 pl-50">
          {{ $t('modules.products._messages.wait_for_importing_operation_to_finish') }}
        </p>
      </div>
    </template>
    <template v-else>
      <b-alert
        show
        variant="info"
        class="mb-50"
      >
        <h4 class="alert-heading">
          {{ $t('modules.products._messages.download_sample_file_for_import') }}
        </h4>
        <div class="alert-body">
          <b-link
            class="alert-link"
            @click="onClickDownloadSample"
          >
            <feather-icon icon="DownloadIcon" />
            {{ $t('modules.products.actions.download_sample_csv_file') }}
          </b-link>
        </div>
      </b-alert>
      <hr>
      <form
        ref="refForm"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          :state="validationState"
          label-for="csv-file-input"
          invalid-feedback="CSV file is required"
        >
          <i-input
            id="csv-file-input"
            v-model="fileValue"
            type="file"
            :title="$t('modules.products.inputs.csv_file')"
            required
            :aria-invalid="validationState"
            :disabled="isProductOperationRequestLoading"
            :readonly="isProductOperationRequestLoading"
            :aria-readonly="isProductOperationRequestLoading"
          />
        </b-form-group>
      </form>
    </template>
  </b-modal>
</template>

<script>
import { onUnmounted, ref } from 'vue'
import {
  BAlert, BFormGroup, BLink, BModal, BSpinner,
} from 'bootstrap-vue'
import { saveAs } from 'file-saver'
import {
  commonRequestErrorsHandler,
  handleValidationError, mediaPath, storagePath, toast,
} from '@core/utils/utils'
import store from '@/store'
import productOperationStoreModule from '@/views/models/products/productOperationStoreModule'
import { t } from '@core/libs/i18n/utils'

export default {
  components: {
    BSpinner,
    BAlert,
    BLink,
    BModal,
    BFormGroup,
  },
  directives: {
  },
  emits: ['operation-finished'],
  methods: {
    storagePath,
    saveAs,
  },
  setup(props, ctx) {
    const OPERATIONS_STORE_MODULE_NAME = 'product_operations'
    // Register module
    if (!store.hasModule(OPERATIONS_STORE_MODULE_NAME)) store.registerModule(OPERATIONS_STORE_MODULE_NAME, productOperationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OPERATIONS_STORE_MODULE_NAME)) store.unregisterModule(OPERATIONS_STORE_MODULE_NAME)
    })

    const refForm = ref(null)
    const refModel = ref(null)
    const validationState = ref(null)
    const fileValue = ref(null)
    const isProductOperationRequestLoading = ref(false)
    const isProductOperationPending = ref(false)
    const isProductOperationRequestFinished = ref(false)
    const activeOperationId = ref(null)
    const checkFormValidity = () => {
      const valid = refForm.value.checkValidity() && !!fileValue.value
      validationState.value = valid
      return valid
    }
    const resetModal = () => {
      fileValue.value = null
      fileValue.uploadedFilePath = null
      validationState.value = null
      isProductOperationRequestLoading.value = false
      isProductOperationPending.value = false
      activeOperationId.value = null
    }
    const getOperationStatus = () => {
      store.dispatch(`${OPERATIONS_STORE_MODULE_NAME}/fetchOne`, activeOperationId.value)
        .then(response => {
          if (response.status === 'pending') {
            setTimeout(() => { getOperationStatus() }, 5000)
          } else if (response.status === 'finished') {
            isProductOperationRequestLoading.value = false
            isProductOperationPending.value = false
            isProductOperationRequestFinished.value = true
            toast('success', 'Products added successfully')
            ctx.emit('operation-finished')
            refModel.value.toggle()
          } else {
            toast('error', 'Something went wrong!')
          }
        })
        .catch(e => {
          isProductOperationRequestLoading.value = false
          refModel.value.toggle()

          if (!commonRequestErrorsHandler(e, false)) {
            toast('danger', 'Something went wrong! Please try again later.', null, 'AlertTriangleIcon')
          }
        })
    }
    const handleSubmit = () => {
      if (!checkFormValidity()) {
        return
      }

      store.dispatch(`${OPERATIONS_STORE_MODULE_NAME}/create`, { operation: 'import', file_path: fileValue.value })
        .then(response => {
          activeOperationId.value = response.operation_id
          isProductOperationPending.value = true
          getOperationStatus()
        })
        .catch(e => {
          isProductOperationRequestLoading.value = false

          commonRequestErrorsHandler(e)

          refModel.value.toggle()
        })
    }
    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmit()
    }

    const onClickDownloadSample = () => {
      const cdn = new URL(mediaPath())

      saveAs(`${cdn.protocol}//${cdn.host}/static/files/import-csv-sample.csv`, 'kssib-sample.csv')
    }

    return {
      refForm,
      refModel,
      validationState,
      fileValue,
      isProductOperationRequestLoading,
      isProductOperationPending,
      isProductOperationRequestFinished,

      checkFormValidity,
      resetModal,
      handleOk,
      handleSubmit,
      onClickDownloadSample,
    }
  },
}
</script>
