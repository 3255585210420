<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-overlay
            :show="isLoading"
            rounded="sm"
            variant="secondary"
            opacity="0.75"
        >
          <b-link
              class="brand-logo"
              :href="platformUrl()"
          >
            <platform-logo />
            <h2 class="brand-text text-primary ml-1">
              {{ platformName() }}
            </h2>
          </b-link>

          <p v-if="alertMessage">
            {{ alertMessage }}
          </p>
          <!-- form -->
          <div v-else ref="registerForm">
            <div class="auth-register-form mt-2">
              <ready-step-store-type
                  v-if="step === 1"
                  :is-first-step="true"
                  :is-final-step="false"
                  :currentData="currentData"
                  @submit="submitStep"
              />

              <ready-step-locale-config
                  v-else-if="step === 2"
                  :is-first-step="false"
                  :is-final-step="false"
                  :currentData="currentData"
                  :industry-options="industryOptions"
                  :country-options="countryOptions"
                  :currency-options="currencyOptions"
                  @submit="submitStep"
                  @back="step--"
              />

              <ready-step-merchant-experience
                  v-else-if="step === 3"
                  :is-first-step="false"
                  :is-final-step="true"
                  :currentData="currentData"
                  :industry-options="industryOptions"
                  :country-options="countryOptions"
                  :currency-options="currencyOptions"
                  @submit="payload => submitStep(payload, true)"
                  @back="step--"
              />
            </div>
          </div>
        </b-overlay>
      </b-card>


      <b-card-text class="text-center mt-2">
        <b-link @click="logout">
          <span>{{ $t('Logout') }}</span>
        </b-link>
      </b-card-text>
    </div>
  </div>

</template>

<script>
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BCol,
  BInputGroupText,
  BInputGroupPrepend, BOverlay, BBadge,
} from 'bootstrap-vue'
import PlatformLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {
  commonRequestErrorsHandler,
  handleValidationError,
  platformName,
  platformUrl,
  toast,
  trackEvent
} from '@core/utils/utils'
import {ref} from "vue";
import axios from "@/libs/axios";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import useModelUpdate from "@/views/models/common/useModelUpdate";
import RadioOption from "@core/components/radio-option/RadioOption.vue";
import ReadyStep1 from "@/views/pages/authentication/Ready-Step1.vue";
import ReadyStepStoreType from "@/views/pages/authentication/ReadyV2/ReadyStepStoreType.vue";
import ReadyStepLocaleConfig from "@/views/pages/authentication/ReadyV2/ReadyStepLocaleConfig.vue";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import ReadyStepMerchantExperience from "@/views/pages/authentication/ReadyV2/ReadyStepMerchantExperience.vue";

export default {
  components: {
    ReadyStepMerchantExperience,
    ReadyStepLocaleConfig,
    ReadyStepStoreType,
    ReadyStep1,
    BBadge,
    RadioOption,
    BOverlay,
    BInputGroupPrepend,
    BInputGroupText,
    BCol,
    PlatformLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      username: '',
      password: '',
      status: '',
      storeData: {
        name: {
          en: null,
          ar: null,
        },
        industry_code: null,
        country_code: null,
        currency_code: null,
        store_type: null,
      },
      remoteErrors: {},

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    trackEvent('InitiateReadiness')
  },
  methods: {
    platformUrl,
    platformName,
    validationError(attr) {
      if (attr === 'name') {
        return this.validationError('name.ar') || this.validationError('name.en')
      }
      return this.remoteErrors[`${attr}`]?.[0] || null
    },
    validationErrorsArray(attr) {
      const error = this.validationError(attr)
      return error ? [error] : []
    },
    performSubmit(payload) {


    },
    validationForm() {
      this.$refs.registerForm.validateWithInfo().then(({ isValid, errors, fields }) => {
        if (isValid) {
          this.performSubmit(this.storeData)
        } else {
          toast('warning', this.$t('message.fill_required_fields'))

          const failedFields = Object.keys(fields).filter(key => fields[key].failed)
          const { focusOnTabsOfErrors } = useModelUpdate(null, null, null ,null)
          focusOnTabsOfErrors(failedFields, this.$refs)
        }
      })
    },
    logout() {
      useJwt.logoutAndRevokeToken().then(() => {
        useJwt.redirectToLoginPage()
      })
    },
  },
  setup() {
    const { t } = useI18nUtils()
    const countryOptions = ref([])
    const currencyOptions = ref([])
    const industryOptions = ref([])
    const alertMessage = ref(null)
    const step = ref(1)
    const currentData = ref({})
    const isLoading = ref(false)
    const backendValidationErrors = ref({})

    const submitStep = (payload, isFinalStep = false) => {
      isLoading.value = true
      axios
          .put(`dashboard/v1/ready`, payload)
          .then(response => {
            if (isFinalStep) {
              localStorage.setItem('store.currency', response.data.currency_code || response.data.currency_code)
              // router.push({ name: 'home' })    // We need to reload the page to use the currency
              window.location.replace("/");
              trackEvent('CompleteReadiness', response.data)
              return response
            }

            step.value += 1
          })
          .catch(err => {
            commonRequestErrorsHandler(err)
          })
          .finally(() => {
            isLoading.value = false
          })
    }


    axios
        .get(`dashboard/v1/ready-options`)
        .then(response => {
          if (response.data?.is_ready) {
            router.push({ name: 'home' })
            return
          }
          if (response.data?.is_coming_soon) {
            alertMessage.value = response.data.message
            return
          }
          countryOptions.value = response.data.countries
          currencyOptions.value = response.data.currencies
          industryOptions.value = response.data.industries

          currentData.value = response.data.current
          if (currentData.value.step) {
            step.value = currentData.value.step
          }
        })

    return {
      countryOptions,
      currencyOptions,
      industryOptions,
      alertMessage,
      step,
      isLoading,
      currentData,
      backendValidationErrors,
      submitStep,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.vs__dropdown-menu {
  max-height: 200px !important;
}
</style>
