<template>
  <b-modal
    id="modal-add-shipment"
    :visible.sync="isVisible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Create')"
    :cancel-title="$t('Cancel')"
    centered
    :title="$t('modules.shipping_methods.actions.create_shipment')"
    @hidden="$emit('hidden')"
    @shown="reset"
    @ok.prevent="onFormSubmit"
  >
    <validation-observer
      ref="refFormObserver"
    >
      <b-form @submit.stop.prevent="onFormSubmit">
        <b-form-group>
          <label for="courier">{{ $t('inputs.shipping_method') }}</label>
          <b-form-input
            id="courier"
            type="text"
            placeholder="Courier"
            readonly="readonly"
            :value="shipment.courier.name"
          />
        </b-form-group>
        <b-form-group>
          <i-input
            v-model="shipmentData.tracking_id"
            :errors="validationErrors.tracking_id"
            type="text"
            title="modules.shipping_methods.inputs.tracking_number"
            description="modules.shipping_methods.inputs.tracking_number:description"
            placeholder="xxxxxxxxxxxxx"
          />
        </b-form-group>
        <b-form-group>
          <i-input
            v-model="shipmentData.tracking_url"
            :errors="validationErrors.tracking_url"
            type="url"
            title="modules.shipping_methods.inputs.tracking_url"
            description="modules.shipping_methods.inputs.tracking_url:description"
            placeholder="https://xxxxxxxxxx"
          />
        </b-form-group>
      <!--      <b-form-group>-->
      <!--        <i-input-->
      <!--          v-model="orderData.shipping_method.status"-->
      <!--          :errors="validationErrors.status"-->
      <!--          type="select"-->
      <!--          :options="shipmentStatusOptions"-->
      <!--          title="modules.shipping_methods.inputs.shipment_status"-->
      <!--          description="modules.shipping_methods.inputs.shipment_status:description"-->
      <!--        />-->
      <!--      </b-form-group>-->
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'

import {
  onUnmounted, ref,
} from 'vue'
import store from '@/store'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import shipmentStoreModule from '@/views/models/orders/shipmentStoreModule'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    BFormInput,
    BModal,
    BFormGroup,
    // BSV
    BForm,

  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    shipment: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  setup(props, ctx) {
    const { t } = useI18nUtils()
    // ? This is just dummy details
    const shipmentData = ref({
      id: null,
      tracking_id: null,
      tracking_url: null,
    })

    const isLoading = ref(false)
    const refs = ref({})

    const SHIPMENTS_STORE_MODULE_NAME = 'shipments'

    // Register module
    if (!store.hasModule(SHIPMENTS_STORE_MODULE_NAME)) store.registerModule(SHIPMENTS_STORE_MODULE_NAME, shipmentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SHIPMENTS_STORE_MODULE_NAME)) store.unregisterModule(SHIPMENTS_STORE_MODULE_NAME)
    })

    const {
      validationErrors,
      save,
    } = useModelUpdate(
      SHIPMENTS_STORE_MODULE_NAME,
      refs,
      shipmentData,
      data => {
        ctx.emit('hidden')
        ctx.emit('submitted')
      },
      null,
      null,
    )

    const onFormSubmit = () => {
      save()
    }

    const reset = () => {
      shipmentData.value.id = props.shipment.id
      shipmentData.value.tracking_id = props.shipment.tracking_id
      shipmentData.value.tracking_url = props.shipment.tracking_url
    }
    reset()

    return {
      isLoading,
      shipmentData,
      refs,
      validationErrors,
      onFormSubmit,
      reset,
    }
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>

</style>
