<template>
  <validation-observer ref="refForm">
    <b-form
      class="auth-register-form mt-2"
      @submit.prevent="validateStep"
    >
      <div class="position-relative">
        <b-card-title class="mb-1">
          {{ $t('auth.ready.cards.store_type.title') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('auth.ready.cards.store_type.subtitle') }}
        </b-card-text>
        <b-tabs
          v-model="stepData.store_type_id"
          fill
          justified
        >
          <b-tab
            v-for="type in storeTypeOptions"
            :key="type"
          >
            <template #title>
              <feather-icon
                v-if="stepData.store_type === type"
                icon="CheckIcon"
              />
              <feather-icon
                v-else
                icon="CircleIcon"
              />
              {{ $t(`auth.ready.store_type_options.${type}.title`) }}
            </template>
            <b-card-text>
              <img
                :src="images[type]"
                alt=""
                class="store-type-illustration"
              >
              {{ $t(`auth.ready.store_type_options.${type}.description`) }}
            </b-card-text>
          </b-tab>
        </b-tabs>
        <!-- submit button -->
        <b-button
          variant="primary"
          block
          type="submit"
          class="mt-2"
        >
          {{ $t(isFinalStep ? 'Save' : 'Next') }}
        </b-button>
        <b-button
          v-if="!isFirstStep"
          variant="light"
          block
          type="button"
          @click="$emit('back')"
        >
          {{ $t('Back') }}
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  platformName, platformUrl, toast, trackEvent,
} from '@core/utils/utils'
import {
  BButton, BCardText, BCardTitle, BForm, BTab, BTabs,
} from 'bootstrap-vue'
import { ref, watch } from 'vue'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import { ValidationObserver } from 'vee-validate'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BForm,
    BCardText,
    BCardTitle,
    BTabs,
    BTab,
    BButton,
    // BSV
    // validations
    // ValidationProvider,
    ValidationObserver,
  },
  mixins: [],
  emits: ['submit', 'back'],
  props: {
    isFirstStep: {
      type: Boolean,
      default: false,
    },
    isFinalStep: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
      default: () => ({}),
    },
    remoteErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      images: {
        retail: require('@/assets/images/illustration/retail_green.svg'),
        drop_shipping: require('@/assets/images/illustration/dropshipping_blue.svg'),
        affiliate: require('@/assets/images/illustration/affiliate_purple.svg'),
      },
      // storeType: 'retail',
    }
  },
  mounted() {
    trackEvent('InitiateReadiness')
    // Set value of refs
    if (this.refs) {
      this.refs.value = this.$refs
    }
  },
  methods: {
    platformUrl,
    platformName,
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const refForm = ref(null)
    const refs = ref({})
    const storeTypeOptions = ['retail', 'drop_shipping', 'affiliate']
    const initialStep = storeTypeOptions.indexOf(props.currentData.store_type || storeTypeOptions[0])
    const stepData = ref({
      step: 1,
      store_type_id: initialStep >= 0 ? initialStep : 0,
      store_type: props.currentData.store_type || storeTypeOptions[0],
    })

    const validateStep = () => {
      refForm.value.validateWithInfo().then(({ isValid, errors, fields }) => {
        if (isValid) {
          emit('submit', stepData.value)
        } else {
          toast('warning', t('message.fill_required_fields'))

          const failedFields = Object.keys(fields).filter(key => fields[key].failed)
          const { focusOnTabsOfErrors } = useModelUpdate(null, null, null, null)
          focusOnTabsOfErrors(failedFields, refs)
        }
      })
    }

    watch(stepData.value, () => {
      const optionId = stepData.value.store_type_id
      stepData.value.store_type = optionId ? (storeTypeOptions[optionId] || storeTypeOptions[0]) : storeTypeOptions[0]
    })

    return {
      refForm,
      refs,
      stepData,
      storeTypeOptions,
      validateStep,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.vs__dropdown-menu {
  max-height: 200px !important;
}

.ready-submit-btn {
  bottom: 22px;
  z-index: 99;
}

.store-type-illustration {
  height: 300px;
  width: auto;
  display: block;
  margin: auto;
}
</style>
