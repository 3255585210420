var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-developer-meetup",attrs:{"no-body":""}},[_c('b-card-body',[_c('div',{staticClass:"meetup-header d-flex align-items-center"},[_c('div',{staticClass:"meetup-day"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" 10 - 22 ")]),_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('cards.onboarding.minutes'))+" ")])]),_c('div',{staticClass:"my-auto"},[_c('b-card-title',{staticClass:"mb-25"},[_vm._v(" "+_vm._s(_vm.$t('cards.onboarding.title'))+" ")]),_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('cards.onboarding.subtitle'))+" ")])],1),_c('div',{staticClass:"ml-auto p-2"},[_c('b-button',{attrs:{"variant":"outline-primary","pill":"","href":"https://www.youtube.com/watch?v=VTbgB3x8g4c&t=59s","target":"_blank"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"HelpCircleIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('cards.onboarding.help_btn_label')))])],1)],1)]),_c('app-task-steps',{attrs:{"accordion":"","type":"border"}},[_c('app-task-steps-item',{ref:"brand_step",attrs:{"title":_vm.$t('cards.onboarding.brand.title'),"duration":("2 " + (_vm.$t('cards.onboarding.minutes'))),"is-visible":_vm.activeStep === 'brand'},on:{"update":function (params) { return _vm.onTaskProgressUpdate('brand', params.value, function () {
          params.callback()
          _vm.steps.brand = params.value
        }); }},model:{value:(_vm.steps.brand),callback:function ($$v) {_vm.$set(_vm.steps, "brand", $$v)},expression:"steps.brand"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('cards.onboarding.brand.description'))+" ")]),_c('b-button',{directives:[{name:"t",rawName:"v-t",value:('cards.onboarding.brand.button_title'),expression:"'cards.onboarding.brand.button_title'"}],staticClass:"mr-2",attrs:{"variant":"primary","to":{ name: 'generalSettings', params: {category: 'store', subcategory: 'general'} }}})],1),_c('img',{staticClass:"onboarding-illustration",attrs:{"src":_vm.stepsImages.brand,"alt":""}})])]),_c('app-task-steps-item',{ref:"product_step",attrs:{"title":_vm.$t('cards.onboarding.product.title'),"duration":("2-5 " + (_vm.$t('cards.onboarding.minutes'))),"is-visible":_vm.activeStep === 'product'},on:{"update":function (params) { return _vm.onTaskProgressUpdate('product', params.value, function () {
          params.callback()
          _vm.steps.product = params.value
        }); }},model:{value:(_vm.steps.product),callback:function ($$v) {_vm.$set(_vm.steps, "product", $$v)},expression:"steps.product"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('cards.onboarding.product.description'))+" ")]),_c('b-button',{directives:[{name:"t",rawName:"v-t",value:('cards.onboarding.product.button_title'),expression:"'cards.onboarding.product.button_title'"}],staticClass:"mr-2",attrs:{"variant":"primary","to":{ name: 'products.add' }}})],1),_c('img',{staticClass:"onboarding-illustration",attrs:{"src":_vm.stepsImages.product,"alt":""}})])]),_c('app-task-steps-item',{ref:"payment_step",attrs:{"title":_vm.$t('cards.onboarding.payment.title'),"duration":("1-3 " + (_vm.$t('cards.onboarding.minutes'))),"is-visible":_vm.activeStep === 'payment'},on:{"update":function (params) { return _vm.onTaskProgressUpdate('payment', params.value, function () {
          params.callback()
          _vm.steps.payment = params.value
        }); }},model:{value:(_vm.steps.payment),callback:function ($$v) {_vm.$set(_vm.steps, "payment", $$v)},expression:"steps.payment"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('cards.onboarding.payment.description'))+" ")]),_c('b-button',{directives:[{name:"t",rawName:"v-t",value:('cards.onboarding.payment.button_title'),expression:"'cards.onboarding.payment.button_title'"}],staticClass:"mr-2",attrs:{"variant":"primary","to":{ name: 'paymentSettings' }}})],1),_c('img',{staticClass:"onboarding-illustration",attrs:{"src":_vm.stepsImages.payment,"alt":""}})])]),_c('app-task-steps-item',{ref:"shipping_step",attrs:{"title":_vm.$t('cards.onboarding.shipping.title'),"duration":("1-5 " + (_vm.$t('cards.onboarding.minutes'))),"is-visible":_vm.activeStep === 'shipping'},on:{"update":function (params) { return _vm.onTaskProgressUpdate('shipping', params.value, function () {
          params.callback()
          _vm.steps.shipping = params.value
        }); }},model:{value:(_vm.steps.shipping),callback:function ($$v) {_vm.$set(_vm.steps, "shipping", $$v)},expression:"steps.shipping"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('cards.onboarding.shipping.description'))+" ")]),_c('b-button',{directives:[{name:"t",rawName:"v-t",value:('cards.onboarding.shipping.button_title'),expression:"'cards.onboarding.shipping.button_title'"}],staticClass:"mr-2",attrs:{"variant":"primary","to":{ name: 'shippingSettings' }}})],1),_c('img',{staticClass:"onboarding-illustration",attrs:{"src":_vm.stepsImages.shipping,"alt":""}})])]),_c('app-task-steps-item',{ref:"customize_step",attrs:{"title":_vm.$t('cards.onboarding.layout.title'),"duration":("3 " + (_vm.$t('cards.onboarding.minutes'))),"is-visible":_vm.activeStep === 'customize'},on:{"update":function (params) { return _vm.onTaskProgressUpdate('customize', params.value, function () {
          params.callback()
          _vm.steps.customize = params.value
        }); }},model:{value:(_vm.steps.customize),callback:function ($$v) {_vm.$set(_vm.steps, "customize", $$v)},expression:"steps.customize"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('cards.onboarding.layout.description'))+" ")]),_c('b-button',{directives:[{name:"t",rawName:"v-t",value:('cards.onboarding.layout.button_title'),expression:"'cards.onboarding.layout.button_title'"}],staticClass:"mr-2",attrs:{"variant":"primary","to":{ name: 'layoutSettings' }}})],1),_c('img',{staticClass:"onboarding-illustration",attrs:{"src":_vm.stepsImages.customize,"alt":""}})])]),_c('app-task-steps-item',{ref:"domain_step",attrs:{"title":_vm.$t('cards.onboarding.domain.title'),"duration":("1-4 " + (_vm.$t('cards.onboarding.minutes'))),"is-visible":_vm.activeStep === 'domain'},on:{"update":function (params) { return _vm.onTaskProgressUpdate('domain', params.value, function () {
          params.callback()
          _vm.steps.domain = params.value
        }); }},model:{value:(_vm.steps.domain),callback:function ($$v) {_vm.$set(_vm.steps, "domain", $$v)},expression:"steps.domain"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('cards.onboarding.domain.description'))+" ")]),_c('b-button',{directives:[{name:"t",rawName:"v-t",value:('cards.onboarding.domain.button_title'),expression:"'cards.onboarding.domain.button_title'"}],staticClass:"mr-2",attrs:{"variant":"primary","to":{ name: 'generalSettings', params: {category: 'store', subcategory: 'domain'} }}})],1),_c('img',{staticClass:"onboarding-illustration",attrs:{"src":_vm.stepsImages.domain,"alt":""}})])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }